import React from 'react';
import cls from 'classname';

const LockedBlock = ({ width, height, color, className, style, ...props }) => (
  <svg width={width ? width : '64'}
       height={height ? height : '64'}
       viewBox="0 0 64 64"
       fill="none"
       className={cls(className, 'icon')}
       style={style}
       xmlns="http://www.w3.org/2000/svg"
  >
    <rect width={width ? width : '64'}
          height={height ? height : '64'}
          rx="12"
          fill={color ? color : '#237CFB'}
    />
    <path fillRule="evenodd" clipRule="evenodd"
          d="M26.4553 28H37.5445C38.3059 28 38.9628 28 39.5038 28.0408C40.0749 28.0838 40.6429 28.1789 41.1894 28.436C42.0048 28.8195 42.6677 29.4314 43.0831 30.184C43.3616 30.6885 43.4646 31.2128 43.5113 31.74C43.5555 32.2394 43.5555 32.8458 43.5555 33.5486V39.7849C43.5555 40.4877 43.5555 41.094 43.5113 41.5934C43.4646 42.1205 43.3616 42.6448 43.0831 43.1493C42.6677 43.9019 42.0048 44.5139 41.1894 44.8974C40.6429 45.1544 40.0749 45.2495 39.5038 45.2926C38.9628 45.3334 38.3059 45.3334 37.5444 45.3333H26.4553C25.6939 45.3334 25.037 45.3334 24.496 45.2926C23.9249 45.2495 23.3569 45.1544 22.8104 44.8974C21.995 44.5139 21.3321 43.9019 20.9166 43.1493C20.6382 42.6448 20.5352 42.1205 20.4885 41.5934C20.4443 41.094 20.4443 40.4876 20.4443 39.7848V33.5486C20.4443 32.8457 20.4443 32.2394 20.4885 31.74C20.5352 31.2128 20.6382 30.6885 20.9166 30.184C21.3321 29.4314 21.995 28.8195 22.8104 28.436C23.3569 28.1789 23.9249 28.0838 24.496 28.0408C25.037 28 25.6939 28 26.4553 28ZM31.9999 36C31.2021 36 30.5554 36.597 30.5554 37.3333V38.6667C30.5554 39.403 31.2021 40 31.9999 40C32.7976 40 33.4443 39.403 33.4443 38.6667V37.3333C33.4443 36.597 32.7976 36 31.9999 36Z"
          fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M26.2222 24.0003C26.2222 21.0548 28.809 18.667 31.9999 18.667C35.1909 18.667 37.7777 21.0548 37.7777 24.0003V28.0003H34.8888V24.0003C34.8888 22.5276 33.5954 21.3337 31.9999 21.3337C30.4045 21.3337 29.1111 22.5276 29.1111 24.0003V28.0003H26.2222V24.0003Z"
          fill="white"/>
  </svg>

);

export default LockedBlock;
