import React, {useState, useEffect} from 'react'
import {useTransition, animated} from 'react-spring'
import {connect} from 'react-redux'
import {Link, useHistory} from 'react-router-dom'
import {push, goBack} from 'connected-react-router'
import {Layout, Card, Spin} from 'antd'

// COMPONENTS
import LoginForm from '../../Components/Forms/LoginForm'
import Images from '../../Images'

// REDUCER
import AuthActions from './reducer'

const slides = [
  Images.slide1,
  Images.slide2,
  Images.slide3,
  Images.slide4,
]

function LoginPage(props) {
  const {auth, error, fetching, rememberMe} = props

  const [index, set] = useState(0)
  const transitions = useTransition(index, {
    key: index,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 6000 },
    onRest: (_a, _b, item) => {
      if (index === item) {
        set(state => (state + 1) % slides.length)
      }
    },
    exitBeforeEnter: true,
  })

  const history = useHistory()

  function handleSubmit(data) {
    props.signIn(data.email, data.password)
  }

  useEffect(() => {
    if (auth.userInfo && !auth.isAdmin) {
      history.push('/main')
    } else if (auth.userInfo && auth.isAdmin) {
      history.push('/admin/customers')
    } else {
      document.title = 'Login - MetaSOA'
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [])

  useEffect(() => {
    if (error) {
      props.resetError()
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [error])

  return (
    <Layout>
      <Spin size="large" spinning={fetching}>
        <div className="login-row">

          {
            transitions((style, i) => (
            <animated.div
              className='bg-img'
              style={{
                ...style,
                backgroundImage: `url(${slides[i]})`,
              }}
            />
          ))
          }

          <div className="login-content">
            <div className="content-main">

              <div className="content-left">
                <img src={Images.DTGlogo} alt="" className="logo"/>

                <Card className="login-card">
                  <span className="card-title-subtitle">Welcome to Della Toffola Group</span>
                  <h2 className="card-title">Digital Hub</h2>
                  <LoginForm
                    rememberMe={rememberMe}
                    setRemember={props.setRemember}
                    onSubmit={handleSubmit}
                    error={error?.message}
                  >
                    <div className="sign-up-link sign-up-link_12">
                      Need assistance? <Link to="#">Contact us</Link>
                    </div>
                  </LoginForm>
                </Card>
              </div>

            </div>
          </div>
        </div>
      </Spin>
    </Layout>
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  error: state.auth.error,
  fetching: state.auth.fetching,
  rememberMe: state.auth.rememberMe,
  isMobile: state.nav.isMobile,
})

// wraps dispatch to create nicer functions to call within our component
const mapDispatchToProps = (dispatch) => ({
  navPush: (path, state) => dispatch(push(path, state)),
  navGoBack: () => dispatch(goBack()),
  signIn: (email, password) =>
    dispatch(AuthActions.signInRequest(email, password)),
  resetError: () => dispatch(AuthActions.authErrorReset()),
  setRemember: (data) => dispatch(AuthActions.setRemember(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
