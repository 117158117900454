import React from 'react';

const SupplierAlibaba = (props) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24" r="23.5" fill="#FF6600" stroke="#C63A31"/>
    <path
      d="M10.2193 30.4056C10.0821 30.3919 9.86263 30.2684 9.71173 30.1449C8.2439 28.7045 9.74466 26.5776 11.9395 25.6174V22.8838C12.4471 23.213 13.0193 23.2996 13.033 23.3408L14.8011 22.065L14.1264 20.1501C19.0238 18.4628 20.5359 18.0887 24.5142 17.4165L24.0483 16.7561L25.6247 15.7764C28.2998 16.6543 30.9671 17.3537 30.5282 19.6034C30.6379 18.0807 28.5055 17.5655 25.6247 16.7561L25.0623 17.099L26.1323 17.977C21.3721 18.8001 18.4364 19.7329 14.952 20.9401L15.4322 22.1747L13.6763 23.9169C13.9643 23.9992 17.0235 25.0144 20.1923 22.8058C20.1923 22.8058 20.2609 22.7646 20.2609 22.7372C20.1649 22.5863 19.9454 22.4079 19.6848 22.1747C20.6176 22.2433 21.2212 23.0527 21.1114 23.9032H20.6725C20.6999 23.6288 20.645 23.3271 20.549 23.135C18.0935 24.9321 15.2401 25.1927 12.812 24.3422V26.4685C11.6323 26.88 9.5334 28.1833 9.54712 29.4453C9.62943 30.0352 9.93122 30.2547 10.2193 30.4056Z"
      fill="#C63A31"/>
    <path
      d="M20.2641 29.7028C17.6303 31.0335 15.1061 32.1035 11.2239 32.2544C6.16196 32.1446 5.38003 28.7837 7.76697 25.2719C10.0305 21.7326 13.6246 18.5226 19.3313 16.5198C20.9912 15.9299 23.4193 15.2714 25.7651 15.2303C29.1397 15.2028 32.4183 16.2454 32.1988 19.4829C32.0754 21.9658 28.495 25.4502 26.6156 27.9606C25.8062 29.0581 25.669 29.7714 27.0546 29.7028C32.0617 29.3736 36.5886 27.6451 40.8001 25.848C37.9467 27.7823 23.2272 35.0666 23.1724 30.1006C23.1861 29.4696 23.4742 28.7974 23.9543 28.0841C24.4207 27.3708 25.0655 26.63 25.7376 25.8755C26.7391 24.7369 29.2357 21.9933 30.0725 20.3471C31.4855 17.2743 28.3304 17.1234 25.6005 16.1631L24.4344 16.8216L25.1478 17.4937C21.1695 18.1522 19.2078 18.8107 14.3105 20.498L14.8729 21.9247L13.213 23.5708C13.1719 23.5297 12.925 23.516 12.4037 23.1867V25.9304C10.2225 26.8906 8.32941 28.5505 9.79724 29.9909C9.94814 30.1144 10.1676 30.2378 10.3048 30.2515C12.9936 31.7605 20.2778 29.7028 20.2641 29.7028Z"
      fill="white"/>
  </svg>
);

export default SupplierAlibaba;
