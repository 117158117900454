import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';

export const BD = ({ width, height, className, fill, outline, theme }) =>
  outline ? (
    <svg
      width={width}
      height={height}
      className={cls(className, 'icon icon-bd icon-bd-bold')}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6939 7.03884C12.6939 8.2744 13.6955 9.27601 14.9311 9.27601C16.1666 9.27601 17.1682 8.2744 17.1682 7.03884C17.1682 5.80328 16.1666 4.80166 14.9311 4.80166C13.6955 4.80166 12.6939 5.80328 12.6939 7.03884ZM14.9311 11.077C13.0102 11.077 11.4026 9.73588 10.9936 7.93893L6.41525 7.93893C5.91792 7.93893 5.51475 7.53576 5.51475 7.03843C5.51475 6.5411 5.91792 6.13793 6.41525 6.13793L10.9938 6.13793C11.4031 4.34139 13.0105 3.00067 14.9311 3.00067C17.1613 3.00067 18.9692 4.80862 18.9692 7.03884C18.9692 9.26906 17.1613 11.077 14.9311 11.077ZM11.3061 16.9612C11.3061 18.1967 10.3045 19.1983 9.06893 19.1983C7.83337 19.1983 6.83175 18.1967 6.83175 16.9612C6.83175 15.7256 7.83337 14.724 9.06893 14.724C10.3045 14.724 11.3061 15.7256 11.3061 16.9612ZM9.06893 20.9993C10.9895 20.9993 12.597 19.6585 13.0063 17.8619L17.5847 17.8619C18.0821 17.8619 18.4852 17.4587 18.4852 16.9614C18.4852 16.4641 18.0821 16.0609 17.5847 16.0609L13.0064 16.0609C12.5973 14.264 10.9897 12.923 9.06893 12.923C6.83871 12.923 5.03076 14.7309 5.03076 16.9612C5.03076 19.1914 6.83871 20.9993 9.06893 20.9993Z"
        fill="#6E7DAF"
      />
    </svg>
  ) : (
    <svg
      width={width}
      height={height}
      className={cls(className, 'icon icon-bd icon-bd-bold')}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3383 16.9603C10.3383 17.6611 9.77023 18.2292 9.06946 18.2292C8.36869 18.2292 7.80061 17.6611 7.80061 16.9603C7.80061 16.2596 8.36869 15.6915 9.06946 15.6915C9.77023 15.6915 10.3383 16.2596 10.3383 16.9603ZM9.06946 20.9976C10.8132 20.9976 12.2987 19.8922 12.8634 18.3439L17.5836 18.3439C18.348 18.3439 18.9678 17.7241 18.9678 16.9597C18.9678 16.1952 18.348 15.5755 17.5836 15.5755L12.8629 15.5755C12.2978 14.0279 10.8126 12.9231 9.06946 12.9231C6.83975 12.9231 5.03221 14.7306 5.03221 16.9603C5.03221 19.1901 6.83975 20.9976 9.06946 20.9976ZM13.6613 7.03969C13.6613 7.74046 14.2294 8.30855 14.9302 8.30855C15.631 8.30855 16.199 7.74046 16.199 7.03969C16.199 6.33893 15.631 5.77084 14.9302 5.77084C14.2294 5.77084 13.6613 6.33893 13.6613 7.03969ZM14.9302 11.0769C13.1865 11.0769 11.7011 9.97159 11.1363 8.42337L6.41643 8.42337C5.65196 8.42337 5.03223 7.80364 5.03223 7.03917C5.03223 6.2747 5.65196 5.65497 6.41643 5.65497L11.1367 5.65497C11.7017 4.1073 13.1869 3.00244 14.9302 3.00244C17.1599 3.00244 18.9674 4.80998 18.9674 7.03969C18.9674 9.26941 17.1599 11.0769 14.9302 11.0769Z"
        fill="#237CFB"
      />
    </svg>
  );

BD.defaultProps = {
  width: 24,
  height: 24,
  // fill: '#6E7DAE',
  outline: true,
};

BD.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
  outline: PropTypes.bool,
};

export default BD;
