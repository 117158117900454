import React from 'react'
import cls from 'classname'

const Spinner = ({width, height, color, className, style, ...props}) => (
  <svg width={width ? width : '60'}
       height={height ? height : '60'}
       style={style}
       className={cls(className, 'icon icon-spinner')}
       viewBox="0 0 100 100"
       xmlns="http://www.w3.org/2000/svg"
  >
    <circle id="spinner"
            style={{
              'fill': 'transparent',
              'stroke': `${color ? color : '#747474'}`,
              'strokeWidth': '6px',
              'strokeLinecap': 'butt',
            }}
            cx="50" cy="50" r="45"/>
  </svg>
)

export default Spinner
