import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import store from './store'

store()
  .then((s) => {
    ReactDOM.render(<App store={s}/>, document.getElementById('root'))
  })
  .catch((err) => {
    // eslint-disable-next-line no-console
    console.error(err.message)
  })
