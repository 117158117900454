import React from 'react'

const AttentionOutline = (props) => (
  <svg width={props.width ? props.width : 44}
       height={props.height ? props.height : 44}
       fill={props.color ? props.color : '#D8DCE9'}
       viewBox="0 0 44 44"
       xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.9994 3.66699C18.3734 3.66699 14.8288 4.74222 11.8139 6.75672C8.799 8.77121 6.44917 11.6345 5.06157 14.9845C3.67396 18.3344 3.3109 22.0207 4.01829 25.577C4.72569 29.1333 6.47177 32.4 9.03573 34.9639C11.5997 37.5279 14.8664 39.274 18.4227 39.9814C21.979 40.6888 25.6652 40.3257 29.0152 38.9381C32.3652 37.5505 35.2285 35.2007 37.243 32.1858C39.2575 29.1709 40.3327 25.6263 40.3327 22.0003C40.3327 19.5928 39.8585 17.2088 38.9371 14.9845C38.0158 12.7602 36.6654 10.7391 34.963 9.0367C33.2606 7.33429 31.2395 5.98387 29.0152 5.06253C26.7909 4.1412 24.4069 3.66699 21.9994 3.66699ZM21.9994 36.667C19.0986 36.667 16.2629 35.8068 13.851 34.1952C11.4391 32.5836 9.55921 30.293 8.44912 27.613C7.33904 24.933 7.04859 21.9841 7.61451 19.139C8.18042 16.2939 9.57729 13.6806 11.6285 11.6294C13.6796 9.57826 16.293 8.18139 19.138 7.61547C21.9831 7.04956 24.9321 7.34001 27.612 8.45009C30.292 9.56018 32.5826 11.44 34.1942 13.852C35.8058 16.2639 36.666 19.0995 36.666 22.0003C36.666 25.8902 35.1208 29.6207 32.3703 32.3712C29.6197 35.1218 25.8892 36.667 21.9994 36.667Z"
      />
    <path
      d="M21.9993 31.1667C23.0119 31.1667 23.8327 30.3459 23.8327 29.3333C23.8327 28.3208 23.0119 27.5 21.9993 27.5C20.9868 27.5 20.166 28.3208 20.166 29.3333C20.166 30.3459 20.9868 31.1667 21.9993 31.1667Z"
      />
    <path
      d="M21.9993 12.833C21.5131 12.833 21.0468 13.0262 20.703 13.37C20.3592 13.7138 20.166 14.1801 20.166 14.6663V23.833C20.166 24.3192 20.3592 24.7856 20.703 25.1294C21.0468 25.4732 21.5131 25.6663 21.9993 25.6663C22.4856 25.6663 22.9519 25.4732 23.2957 25.1294C23.6395 24.7856 23.8327 24.3192 23.8327 23.833V14.6663C23.8327 14.1801 23.6395 13.7138 23.2957 13.37C22.9519 13.0262 22.4856 12.833 21.9993 12.833Z"
      />
  </svg>
)

export default AttentionOutline
