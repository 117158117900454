import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';

export const AliDrops = ({ width, height, className, fill, outline, theme }) =>
  outline ? (
    <svg
      width={width}
      height={height}
      className={cls(className, 'icon icon-alidrops icon-alidrops-bold')}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.12 6.71L17.29 3.88C16.7279 3.31723 15.9654 3.0007 15.17 3H8.83C8.03462 3.0007 7.27207 3.31723 6.71 3.88L3.88 6.71C3.31723 7.27207 3.0007 8.03462 3 8.83V18C3 18.7956 3.31607 19.5587 3.87868 20.1213C4.44129 20.6839 5.20435 21 6 21H18C18.7956 21 19.5587 20.6839 20.1213 20.1213C20.6839 19.5587 21 18.7956 21 18V8.83C20.9993 8.03462 20.6828 7.27207 20.12 6.71ZM8.12 5.29C8.31163 5.10727 8.56523 5.00368 8.83 5H15.17C15.4348 5.00368 15.6884 5.10727 15.88 5.29L17.59 7H6.41L8.12 5.29ZM18 19H6C5.73478 19 5.48043 18.8946 5.29289 18.7071C5.10536 18.5196 5 18.2652 5 18V9H19V18C19 18.2652 18.8946 18.5196 18.7071 18.7071C18.5196 18.8946 18.2652 19 18 19Z"
        fill="#6E7DAF"
      />
      <path
        d="M15 11C14.7348 11 14.4804 11.1054 14.2929 11.2929C14.1054 11.4804 14 11.7348 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14C11.4696 14 10.9609 13.7893 10.5858 13.4142C10.2107 13.0391 10 12.5304 10 12C10 11.7348 9.89464 11.4804 9.70711 11.2929C9.51957 11.1054 9.26522 11 9 11C8.73478 11 8.48043 11.1054 8.29289 11.2929C8.10536 11.4804 8 11.7348 8 12C8 13.0609 8.42143 14.0783 9.17157 14.8284C9.92172 15.5786 10.9391 16 12 16C13.0609 16 14.0783 15.5786 14.8284 14.8284C15.5786 14.0783 16 13.0609 16 12C16 11.7348 15.8946 11.4804 15.7071 11.2929C15.5196 11.1054 15.2652 11 15 11Z"
        fill="#6E7DAF"
      />
    </svg>
  ) : (
    <svg
      width={width}
      height={height}
      className={cls(className, 'icon icon-alidrops icon-alidrops-bold')}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.12 6.71L17.29 3.88C16.7279 3.31723 15.9654 3.0007 15.17 3H8.83C8.03462 3.0007 7.27207 3.31723 6.71 3.88L3.88 6.71C3.31723 7.27207 3.0007 8.03462 3 8.83V18C3 18.7956 3.31607 19.5587 3.87868 20.1213C4.44129 20.6839 5.20435 21 6 21H18C18.7956 21 19.5587 20.6839 20.1213 20.1213C20.6839 19.5587 21 18.7956 21 18V8.83C20.9993 8.03462 20.6828 7.27207 20.12 6.71ZM12 16C10.9391 16 9.92172 15.5786 9.17157 14.8284C8.42143 14.0783 8 13.0609 8 12C8 11.7348 8.10536 11.4804 8.29289 11.2929C8.48043 11.1054 8.73478 11 9 11C9.26522 11 9.51957 11.1054 9.70711 11.2929C9.89464 11.4804 10 11.7348 10 12C10 12.5304 10.2107 13.0391 10.5858 13.4142C10.9609 13.7893 11.4696 14 12 14C12.5304 14 13.0391 13.7893 13.4142 13.4142C13.7893 13.0391 14 12.5304 14 12C14 11.7348 14.1054 11.4804 14.2929 11.2929C14.4804 11.1054 14.7348 11 15 11C15.2652 11 15.5196 11.1054 15.7071 11.2929C15.8946 11.4804 16 11.7348 16 12C16 13.0609 15.5786 14.0783 14.8284 14.8284C14.0783 15.5786 13.0609 16 12 16ZM6.41 7L8.12 5.29C8.31163 5.10727 8.56523 5.00368 8.83 5H15.17C15.4348 5.00368 15.6884 5.10727 15.88 5.29L17.59 7H6.41Z"
        fill="#237CFB"
      />
    </svg>
  );

AliDrops.defaultProps = {
  width: 24,
  height: 24,
  // fill: '#6E7DAE',
  outline: true,
};

AliDrops.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
  outline: PropTypes.bool,
};

export default AliDrops;
