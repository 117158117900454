import React from 'react'

const CheckMark = (props) => (
  <svg width="24"
       height="24"
       fill={props.color ? props.color : '#237CFB'}
       viewBox="0 0 24 24"
       xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.85911 17.9999C9.72199 17.9995 9.58643 17.9708 9.46085 17.9158C9.33527 17.8607 9.22235 17.7804 9.12911 17.6799L4.26911 12.5099C4.08744 12.3163 3.99011 12.0585 3.99855 11.7931C4.00699 11.5277 4.1205 11.2766 4.31411 11.0949C4.50772 10.9132 4.76557 10.8159 5.03093 10.8244C5.29629 10.8328 5.54744 10.9463 5.72911 11.1399L9.84911 15.5299L18.2591 6.32991C18.3445 6.22363 18.4506 6.13593 18.5711 6.07217C18.6916 6.00842 18.8238 5.96997 18.9597 5.95919C19.0956 5.94841 19.2322 5.96551 19.3612 6.00946C19.4903 6.05341 19.6089 6.12327 19.71 6.21476C19.811 6.30624 19.8923 6.41742 19.9488 6.54145C20.0053 6.66549 20.0359 6.79977 20.0386 6.93605C20.0413 7.07233 20.0162 7.20772 19.9647 7.33392C19.9132 7.46012 19.8364 7.57446 19.7391 7.66991L10.5991 17.6699C10.5068 17.7723 10.3942 17.8544 10.2686 17.9112C10.143 17.968 10.007 17.9982 9.86911 17.9999H9.85911Z"
      />
  </svg>
)

export default CheckMark
