import React from 'react'
import {connect} from 'react-redux'
import {useLocation,useHistory} from 'react-router-dom'
import PropTypes from 'prop-types'
import {Layout, Row, Col, Button} from 'antd'
import cls from 'classname'

// styles
import './styles.less'

// actions
import actions from './actions'

import Logo from '../../Components/Logo'
//import Sidebar from '../Sidebar'
import Icon from '../../Icon'
//import User from '../User'
import IconWithText from "../../Components/Text"
import AuthCreators from "../LoginPage/reducer"

export const LayoutComponent = (
  {
    nav,
    children,
    openSidebar,
    isAdmin,
    logout,
    userInfo,
    ...props
  }) => {
  const {theme, isShowSideBar, isShowMenu} = nav
  const {pathname} = useLocation()
  const history = useHistory()
  //const [isUserMenu, setUserMenu] = useState(false)

  const handleStopPixelStreaming = () => {
    history.replace('/main')
  }

  return (
    <Layout
      id="global-wrap"
      className={cls('global-wrap', {
        'theme-light': theme === 'light',
        'theme-dark': theme === 'dark',
        'closed-sidebar': isShowMenu,
        'opened-sidebar': !isShowMenu,
        'small-sidebar': isShowSideBar,
        'big-sidebar': !isShowSideBar,
        //'open-user-menu': isUserMenu && isMobile,
      })}
    >
      <header className="header">
        <div className="header_logo">
          <Logo theme={theme} isAdmin={isAdmin}/>
        </div>
        <div className="header_right-part">

          {
            pathname.includes('pixel_streaming')
              ? <Button type={'primary'}
                        className={'btn-primary btn-cancel border-only'}
                        style={{height: 38, marginRight: 16}}
                        onClick={handleStopPixelStreaming}
              >
                Stop
              </Button>
              : null
          }

          <div className="user_logout" onClick={logout} style={{color: 'rgb(255, 75, 64)'}}>
            <IconWithText
              text="Log out"
              icon={() => <Icon type="logout" role="icon"/>}
            />
          </div>

          {/* <div className="header_item header_item--user">
            <User onClickHandler={setUserMenu}/>
          </div>*/}
        </div>
      </header>
      <div className="main-container">
        {/* <Sidebar/>*/}
        <main className={`main-content ${pathname.slice(1).replace('/', '-')}`}>
          <Row>
            <Col flex="auto">{children}</Col>
          </Row>
        </main>
      </div>
    </Layout>
  )
}

LayoutComponent.defaultProps = {}

LayoutComponent.propTypes = {
  nav: PropTypes.shape({
    isMobile: PropTypes.bool,
    isShowMenu: PropTypes.bool,
    isShowSideBar: PropTypes.bool,
    isShowCartModal: PropTypes.bool,
    theme: PropTypes.string,
  }).isRequired,
  openSidebar: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

const mapStateToProps = (state) => ({
  nav: state.nav,
  isAdmin: state.auth.isAdmin,
  userInfo: state.auth.userInfo
})

const mapDispatchToProps = (dispatch) => ({
  logout() {
    dispatch(AuthCreators.logout())
  },
  openSidebar(value) {
    dispatch(actions.changeNavSetProp('isShowMenu', value))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(LayoutComponent)
