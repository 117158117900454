import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import { Divider, Input } from 'antd';
import Icon from '../../Icon';

import './styles.less';
const { Search } = Input;

export const SearchComponent = ({
  handleSearch,
  button,
  disabled,
  ...props
}) => {
  return (
    <div className="search">
      <div className="search_wrapper">
        <Search
          placeholder={
            props?.placeholder
              ? props?.placeholder
              : 'search products'
          }
          className="search-input"
          onChange={(e) => {
            e.persist();
            handleSearch(e);
          }}
          {...(props?.value ? { value: props?.value } : null)}
          prefix={
            <>
              <Icon type="search_icon" role="icon" />
              <Divider type="vertical" className="search-divider" />
            </>
          }
          disabled={disabled}
        />
        <div className="search_button">{button}</div>
      </div>
    </div>
  );
};

SearchComponent.defaultProps = {};

SearchComponent.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  button: PropTypes.object,
};

export default SearchComponent;
