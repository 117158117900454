import React from 'react';

const SelectDropdownCheckMarkV2 = (props) => (
  <svg width={props.width ? props.width : '24'}
       height={props.height ? props.height : '24'}
       fill={props.color ? props.color : '#237CFB'}
       className={props.className}
       viewBox="0 0 24 24"
       xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.71019 11.2905C9.52188 11.1022 9.26649 10.9964 9.00019 10.9964C8.73388 10.9964 8.47849 11.1022 8.29019 11.2905C8.10188 11.4788 7.99609 11.7342 7.99609 12.0005C7.99609 12.2668 8.10188 12.5222 8.29019 12.7105L11.2902 15.7105C11.3836 15.8032 11.4944 15.8765 11.6163 15.9263C11.7381 15.976 11.8686 16.0012 12.0002 16.0005C12.1371 15.9961 12.2716 15.9637 12.3955 15.9052C12.5193 15.8467 12.6299 15.7634 12.7202 15.6605L19.7202 7.66048C19.8818 7.45981 19.9597 7.20449 19.9377 6.94776C19.9157 6.69104 19.7955 6.45271 19.602 6.28249C19.4086 6.11227 19.1569 6.02328 18.8995 6.03408C18.642 6.04489 18.3987 6.15465 18.2202 6.34048L12.0002 13.5405L9.71019 11.2905Z"
    />
    <path
      d="M21.0008 11C20.7356 11 20.4812 11.1054 20.2937 11.2929C20.1061 11.4804 20.0008 11.7348 20.0008 12C20.0008 14.1217 19.1579 16.1566 17.6576 17.6569C16.1574 19.1571 14.1225 20 12.0008 20C10.4209 19.9993 8.87655 19.5308 7.56255 18.6535C6.24855 17.7763 5.22371 16.5297 4.61727 15.0708C4.01083 13.6119 3.84993 12.0061 4.15488 10.4559C4.45982 8.90569 5.21695 7.48048 6.33079 6.36C7.07149 5.60938 7.95447 5.01404 8.92805 4.60881C9.90163 4.20358 10.9462 3.99661 12.0008 4C12.6402 4.004 13.2773 4.07777 13.9008 4.22C14.0313 4.26035 14.1686 4.27356 14.3044 4.2588C14.4401 4.24404 14.5714 4.20163 14.6902 4.13418C14.8089 4.06673 14.9126 3.97567 14.9948 3.86662C15.077 3.75757 15.136 3.63284 15.1682 3.50011C15.2003 3.36739 15.205 3.22948 15.1818 3.0949C15.1586 2.96032 15.1081 2.83191 15.0334 2.71759C14.9587 2.60327 14.8613 2.50547 14.7474 2.43021C14.6334 2.35495 14.5053 2.30383 14.3708 2.28C13.5939 2.09724 12.7988 2.00331 12.0008 2C10.0251 2.01026 8.09678 2.60552 6.45909 3.71065C4.8214 4.81578 3.54776 6.38126 2.79891 8.20953C2.05006 10.0378 1.85955 12.0469 2.25142 13.9834C2.6433 15.9198 3.6 17.6968 5.00079 19.09C6.85748 20.9475 9.37444 21.9939 12.0008 22C14.653 22 17.1965 20.9464 19.0719 19.0711C20.9472 17.1957 22.0008 14.6522 22.0008 12C22.0008 11.7348 21.8954 11.4804 21.7079 11.2929C21.5204 11.1054 21.266 11 21.0008 11Z"
    />
  </svg>
);

export default SelectDropdownCheckMarkV2;
