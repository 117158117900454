import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';

export const Menu = ({ width, height, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 20"
    className={cls(className, 'icon icon-menu')}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="2" rx="1" />
    <rect y="18" width="19" height="2" rx="1" />
    <rect x="22" y="18" width="10" height="2" rx="1" />
    <rect y="9" width="32" height="2" rx="1" />
  </svg>
);

Menu.defaultProps = {
  width: 32,
  height: 20,
  // fill: '#3A4569',
  theme: 'light',
};

Menu.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  theme: PropTypes.string,
};

export default Menu;
