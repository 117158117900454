import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';

import Layout from '../Layout';
import {Container} from '../../Components/Blocks';
import Search from '../../Components/Search';

import Creators from './reducer';
import PlanCreators from '../../Redux/PlanRedux'

import './styles.less';
import UsersAdminTable from "./components/Table";
import {Button} from "antd";
import {debounce} from "../../Utils/utils";
import SelectRole from "./components/SelectRole";
import ModalComponent from "../../Components/Modal";
import UserForm from "./components/UserForm";
import InputComponent from "../../Components/Input";
import {titleSortFilters} from "../../Components/titleSortFilters";

function UsersAdminPage({loading, result, users_roles, selected_user, dispatch, getUsers, resetErrors, ...props}) {

  //state for role to filter users
  const [selectedRole, setSelectedRole] = useState(null)

  //state for calling req on search input change
  const [searchQuery, setSearchQuery] = useState(null)

  //state for order of sorting for req
  const [sortField, setSortField] = useState(null)

  //modal visibility state
  const [visibleModal, setVisibleModal] = useState(false)

  //modal type state
  const [modalType, setModalType] = useState('create')

  //state for modal form inputs (create/edit)
  const [formValues, setFormValues] = useState({
    email: '', first_name: '', last_name: '', role: 1, password: null
  });

  //ref for tbody for getUsers on scroll
  const tableRef = useRef(null)

  //input onChange handler
  const inputChangeHandler = (event) => {
    event.persist()
    setFormValues(formValues => ({
      ...formValues, [event.target.id]: event.target.value.trim()
    }))
  }

  //modal content based on type
  const ModalContent = {
    create: {
      className: 'create-user-modal admin-page-modal',
      title: 'Create new user',
      children: () => {
        return (
          <UserForm
            onFinish={e => {
              e.preventDefault()
            }}>
            <InputComponent id={'email'}
                            className={'admin_user_modal_input'}
                            onChange={e => inputChangeHandler(e)}
                            placeholder={'Email'}
                            value={formValues.email}
            />
            <InputComponent id={'first_name'}
                            className={'admin_user_modal_input'}
                            onChange={e => inputChangeHandler(e)}
                            placeholder={'First Name'}
                            value={formValues.first_name}
            />
            <InputComponent id={'last_name'}
                            className={'admin_user_modal_input'}
                            onChange={e => inputChangeHandler(e)}
                            placeholder={'Last Name'}
                            value={formValues.last_name}
            />
            <InputComponent id={'password'}
                            className={'admin_user_modal_input'}
                            onChange={e => inputChangeHandler(e)}
                            placeholder={'Password: optional'}
                            value={formValues.password}
            />
            <SelectRole
              data={users_roles}
              id={'role'}
              onChange={value => setFormValues(formValues => ({
                ...formValues, role: value
              }))}
              defaultValue={formValues.role}
            />
          </UserForm>
        )
      },
      buttons: [
        <Button key='cancel_create'
                className={'table__action table__action--delete'}
          //hiding modal on cancel
                onClick={() => setVisibleModal(false)}>
          Cancel
        </Button>,
        <Button key='submit_create'
                className={'table__action'}
                disabled={!(formValues.email && formValues.first_name && formValues.last_name && formValues.email)}
                onClick={() => {
                  //hiding create modal
                  setVisibleModal(false)
                  setTimeout(() => {
                    //showing confirm modal
                    setModalType('confirm_create')
                    setVisibleModal(true)
                  }, 500)
                }}>
          Submit
        </Button>
      ],
    },
    edit: {
      className: 'edit-user-modal admin-page-modal',
      title: `Edit User: ${selected_user.first_name} ${selected_user.last_name}`,
      children: () => {
        return (
          <UserForm
            onFinish={e => {
              e.preventDefault()
            }}>
            <InputComponent id={'email'}
                            className={'admin_user_modal_input'}
                            onChange={e => inputChangeHandler(e)}
                            placeholder={'Email'}
                            value={formValues.email}
            />
            <InputComponent id={'first_name'}
                            className={'admin_user_modal_input'}
                            onChange={e => inputChangeHandler(e)}
                            placeholder={'First Name'}
                            value={formValues.first_name}
            />
            <InputComponent id={'last_name'}
                            className={'admin_user_modal_input'}
                            onChange={e => inputChangeHandler(e)}
                            placeholder={'Last Name'}
                            value={formValues.last_name}
            />
            <SelectRole
              data={users_roles}
              id={'role'}
              onChange={value => setFormValues(formValues => ({
                ...formValues, role: value
              }))}
              defaultValue={formValues.role}
            />
          </UserForm>
        )
      },
      buttons: [
        <Button key='cancel_edit'
                className={'table__action table__action--delete'}
                onClick={() => setVisibleModal(false)}>
          Cancel
        </Button>,
        <Button key='submit_edit'
                className={'table__action'}
                disabled={!(formValues.email &&
                  formValues.first_name &&
                  formValues.last_name &&
                  formValues.email)}
                onClick={() => {
                  setVisibleModal(false)
                  setTimeout(() => {
                    setModalType('confirm_edit')
                    setVisibleModal(true)
                  }, 500)
                }}>
          Submit
        </Button>
      ],
    },
    delete: {
      className: 'delete-user-modal admin-page-modal',
      title: `Confirmation`,
      children: () => {
        return (
          <div>
            You are about to delete: {selected_user.first_name} {selected_user.last_name}
          </div>
        )
      },
      buttons: [
        <Button key='cancel_delete'
                className={'table__action table__action--delete'}
                onClick={() => setVisibleModal(false)}>
          Cancel
        </Button>,
        <Button key='submit_delete'
                className={'table__action'}
                onClick={() => {
                  setVisibleModal(false)
                  setTimeout(() => {
                    setModalType('confirm_delete')
                    setVisibleModal(true)
                  }, 500)
                }}>
          Continue
        </Button>
      ],
    },
    confirm_delete: {
      className: 'confirm-delete-modal admin-page-modal',
      title: `Are you sure?`,
      children: () => {
        return (
          <div>
            Are you sure you want to delete this user?
            <p>Actions can not be reverted!</p>
          </div>
        )
      },
      buttons: [
        <Button key='cancel_delete_confirm'
                className={'table__action table__action--delete'}
                onClick={() => setVisibleModal(false)}>
          Cancel
        </Button>,
        <Button key='submit_delete_confirm'
                className={'table__action'}
                onClick={() => {
                  setVisibleModal(false)
                  //submit deleteReq
                  props.deleteUser(selected_user.id)
                }}>
          Submit
        </Button>
      ],
    },
    confirm_edit: {
      className: 'confirm-edit-modal admin-page-modal',
      title: `Confirmation`,
      children: () => {
        return (
          <div>
            Are you sure you want to edit user’s information?
          </div>
        )
      },
      buttons: [
        <Button key='cancel_edit_confirm'
                className={'table__action table__action--delete'}
                onClick={() => setVisibleModal(false)}>
          Cancel
        </Button>,
        <Button key='submit_edit_confirm'
                className={'table__action'}
                onClick={() => {
                  setVisibleModal(false)
                  const data = {...formValues}
                  delete data.password
                  props.updateUser({...data, id: selected_user.id})
                }}>
          Submit
        </Button>
      ],
    },
    confirm_create: {
      className: 'confirm-create-modal admin-page-modal',
      title: `Confirmation`,
      children: () => {
        return (
          <div>
            {`Are you sure you want to create a user with the role "${users_roles.filter(el => el.id === formValues.role)[0].title}"?`}
          </div>
        )
      },
      buttons: [
        <Button key='cancel_create_confirm'
                className={'table__action table__action--delete'}
                onClick={() => setVisibleModal(false)}>
          No
        </Button>,
        <Button key='submit_create_confirm'
                className={'table__action'}
                onClick={() => {
                  setVisibleModal(false)
                  props.createUser(formValues)
                }}>
          Yes
        </Button>
      ],
    },
    confirm_error: {
      className: 'confirm-create-modal error-confirm-popup admin-page-modal',
      title: `Confirmation`,
      children: () => {
        const result = props?.errors?.message?.detail.split(' ').map(el =>
          el.includes('@') ?
            `<span style="color: #0e70b8">${el}</span>`
            :
            el
        ).join(' ')
        return (
          <div
            dangerouslySetInnerHTML={{__html: typeof props?.errors?.message?.detail === 'string' ? result : 'Sorry'}}/>
        )
      },
      buttons: [
        <Button key='confirm_error'
                className={'table__action'}
                onClick={() => {
                  setVisibleModal(false)
                  resetErrors()
                }}>
          Got it
        </Button>
      ],
    },
    block: {
      className: 'confirm-block-modal admin-page-modal',
      title: `Are you sure?`,
      children: () => {
        return (
          <div>
            Are you sure you want to block this user?
            <p>You can unblock the user anytime!</p>
          </div>
        )
      },
      buttons: [
        <Button key='cancel_block'
                className={'table__action table__action--delete'}
                onClick={() => setVisibleModal(false)}>
          Cancel
        </Button>,
        <Button key='submit_block'
                className={'table__action'}
                onClick={() => {
                  setVisibleModal(false)
                  props.markAsDeletedUser(selected_user.id)
                }}>
          Submit
        </Button>
      ],
    },
    unblock: {
      className: 'confirm-unblock-modal admin-page-modal',
      title: `Are you sure?`,
      children: () => {
        return (
          <div>
            Are you sure you want to unblock this user?
          </div>
        )
      },
      buttons: [
        <Button key='cancel_unblock'
                className={'table__action table__action--delete'}
                onClick={() => setVisibleModal(false)}>
          Cancel
        </Button>,
        <Button key='submit_unblock'
                className={'table__action'}
                onClick={() => {
                  setVisibleModal(false)
                  props.unmarkAsDeletedUser(selected_user.id)
                }}>
          Submit
        </Button>
      ],
    }
  }

  //array of users
  const TableData = result.results || [];

  const TableColumns = [
    {
      //custom sorting arrows
      title: (state) =>
        titleSortFilters({
          title: 'First Name',
          stateData: state,
          key: 'first_name',
        }),
      //data selector
      dataIndex: 'first_name',
      //textAlign in cell
      align: 'center',
      //is Sortable data in column
      sorter: true,
    },
    {
      title: (state) =>
        titleSortFilters({
          title: 'Last Name',
          stateData: state,
          key: 'last_name',
        }),
      dataIndex: 'last_name',
      align: 'center',
      sorter: true,
    },
    {
      title: (state) =>
        titleSortFilters({
          title: 'Email',
          stateData: state,
          key: 'email',
        }),
      dataIndex: 'email',
      align: 'center',
      sorter: true,
    },
    {
      title: 'Role',
      dataIndex: ['role', 'name'],
      align: 'center',
      width: 100,
    },
    {
      title: 'Email confirmation',
      dataIndex: 'is_active',
      align: 'center',
      render: text => text ? <span>Active</span> : <span>Inactive</span>,
      width: 120,
    },
    {
      title: 'Status',
      dataIndex: 'is_deleted',
      align: 'center',
      render: text => text ? <span>Blocked</span> : <span>Unblocked</span>,
      width: 110,
    },
    {
      //button in Action column header
      title: () => (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <span style={{marginLeft: 0}} className={'table__action'}
                onClick={() => {
                  //reset formValues on Create click
                  for (let key in formValues) {
                    if (key === 'role') {
                      setFormValues(formValues => ({
                        ...formValues, [key]: 1
                      }))
                    } else if (key === 'password') {
                      setFormValues(formValues => ({
                        ...formValues, [key]: null
                      }))
                    } else {
                      setFormValues(formValues => ({
                        ...formValues, [key]: ''
                      }))
                    }
                  }
                  //triggering create modal
                  setModalType('create')
                  setVisibleModal(true)
                }}>
            Create User
          </span>
        </div>
      ),
      key: 'action',
      //rendering action buttons Edit, Delete in each row
      render: (text, record) => (
        <div style={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>
          <span className={'table__action'}
                onClick={() => {
                  //updating info on selected user
                  props.getSelectedUser(record.id)
                  //set form values due to selected user (pre defined input values with correct user info)
                  for (let key in formValues) {
                    if (key === 'role') {
                      setFormValues(formValues => ({
                        ...formValues, [key]: record[key].id
                      }))
                    } else if (key === 'password') {
                      setFormValues(formValues => ({
                        ...formValues, [key]: null
                      }))
                    } else {
                      setFormValues(formValues => ({
                        ...formValues, [key]: record[key]
                      }))
                    }
                  }
                  //triggering edit modal
                  setModalType('edit')
                  setVisibleModal(true)
                }}
          >Edit</span>
          <span className={'table__action table__action--block'}
                onClick={() => {
                  props.getSelectedUser(record.id)
                  setModalType(record.is_deleted ? 'unblock' : 'block')
                  setVisibleModal(true)
                }}
          >{record.is_deleted ? 'Unblock' : 'Block'}</span>
          <span className={'table__action table__action--delete'}
                onClick={() => {
                  props.getSelectedUser(record.id)
                  setModalType('delete')
                  setVisibleModal(true)
                }}
          >Delete</span>
        </div>
      ),
      width: 300,
    },
  ];

  //search handler with debounce
  const handleSearch = debounce((event) => {
    let val = event.target.value.trim().toLowerCase()
    if (val !== '') setSearchQuery(val)
    else setSearchQuery(null)
  }, 500)

  //select change handler
  const handleSelect = value => {
    if (value === 'All roles') setSelectedRole(null)
    else setSelectedRole(value)
  }

  //function to pass to table sorter and set sort type
  const handleTableChange = ({order, field}) => {
    if (order && field) {
      const ordering = order === 'ascend' ? field : `-${field}`
      setSortField(ordering)
    } else setSortField(null)

  }

  //init useEffect
  useEffect(() => {
    document.title = 'Customers - MetaSOA'
    getUsers();
    props.getUsersRoles();
    dispatch(PlanCreators.plansRequest());
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  //getUsers req trigger on search input, select, sort changes
  useEffect(() => {
    getUsers({q: searchQuery, role: selectedRole, ordering: sortField})
  }, [getUsers, searchQuery, selectedRole, sortField])

  useEffect(() => {
    if (props?.errors) {
      setModalType('confirm_error')
      setVisibleModal(true)
    }
  }, [props.errors, resetErrors])

  //get next bunch of users on table scroll down (pagination)
  function onScrollHandlerFunc(event) {
    const maxTopScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentTopScroll = event.target.scrollTop;
    if (currentTopScroll >= maxTopScroll && !loading && !!result.next) {
      props.getNextUsers(result.next);
      event.target.removeEventListener('scroll', onScrollHandlerFunc);
    }
  }

  //add scroll listener to table if it's not empty
  useEffect(() => {
    const tableContent = tableRef.current?.parentNode;
    if (tableContent) {
      tableContent.addEventListener('scroll', onScrollHandlerFunc);
    }

    //remove scroll listener if table is empty
    return () =>
      tableContent
        ? tableContent.removeEventListener('scroll', onScrollHandlerFunc)
        : null;
  });

  return (
    <Layout>
      <Container className="portfolio-admin-page">
        <div className="admin-table-options">
          <Search
            placeholder='Search by First Name, Last Name, Email'
            handleSearch={(e) => {
              e.persist()
              handleSearch(e)
            }}
          />
          <SelectRole data={users_roles} onChange={(value) => {
            handleSelect(value)
          }}/>
        </div>
        <UsersAdminTable
          loading={loading}
          reference={tableRef}
          data={TableData}
          columns={TableColumns}
          onSort={handleTableChange}
        />
        <ModalComponent
          title={ModalContent[modalType].title}
          visible={visibleModal}
          isMobile={props.isMobile}
          children={ModalContent[modalType].children()}
          buttons={ModalContent[modalType].buttons}
          handleClose={() => {
            setVisibleModal(false)
            resetErrors()
          }}
          //clear modal cache (modal content doesn't update without it)
          destroyOnClose={true}
          className={ModalContent[modalType]?.className}
        />
      </Container>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  loading: state.portfolioAdmin.loading,
  result: state.portfolioAdmin.result,
  users_roles: state.portfolioAdmin.users_roles,
  selected_user: state.portfolioAdmin.selected_user,
  errors: state.portfolioAdmin.errors,
  isMobile: state.nav.isMobile
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getUsers: (data) => dispatch(Creators.getUsersRequest(data)),
  getUsersRoles: () => dispatch(Creators.getUsersRolesRequest()),
  getSelectedUser: (id) => dispatch(Creators.getSelectedUserRequest(id)),
  updateUser: (data) => dispatch(Creators.updateUserRequest(data)),
  createUser: (data) => dispatch(Creators.createUserRequest(data)),
  deleteUser: (id) => dispatch(Creators.deleteUserRequest(id)),
  markAsDeletedUser: (id) => dispatch(Creators.markAsDeletedUserRequest(id)),
  unmarkAsDeletedUser: (id) => dispatch(Creators.unmarkAsDeletedUserRequest(id)),
  getNextUsers: (data) => dispatch(Creators.getNextUsersRequest(data)),
  resetErrors: () => dispatch(Creators.resetErrors())
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersAdminPage);
