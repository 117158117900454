import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';

const AppleIcon = ({ width, height, className }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cls(className, 'icon icon-apple')}
  >
    <path
      d="M33.1437 26.1779C32.0208 24.5915 31.4597 22.8042 31.4597 20.8175C31.4597 19.0066 31.9892 17.3476 33.0461 15.8414C33.6156 15.0241 34.5434 14.0789 35.8289 13.005C34.9829 11.9797 34.1368 11.1784 33.2901 10.6013C31.7606 9.55981 30.0274 9.03906 28.0912 9.03906C26.9362 9.03906 25.5283 9.31135 23.8689 9.8566C22.2742 10.4014 21.1106 10.6741 20.3787 10.6741C19.8252 10.6741 18.7026 10.4339 17.0103 9.95291C15.3018 9.47208 13.8616 9.23187 12.6901 9.23187C9.89129 9.23187 7.58066 10.3858 5.75826 12.6933C3.91953 15.0331 3 18.0297 3 21.6836C3 25.5618 4.19627 29.5919 6.58811 33.7746C9.01245 37.9252 11.4615 40.0007 13.9348 40.0007C14.7647 40.0007 15.8387 39.7282 17.1565 39.1834C18.4748 38.655 19.63 38.3902 20.6227 38.3902C21.6801 38.3902 22.9088 38.6465 24.3081 39.1591C25.7887 39.6714 26.928 39.9279 27.7252 39.9279C29.808 39.9279 31.8992 38.3573 33.998 35.2164C35.3646 33.2134 36.3654 31.2103 37 29.2069C35.5518 28.7739 34.2665 27.7643 33.1437 26.1779Z"
      fill="black"
    />
    <path
      d="M24.8464 7.59598C25.8718 6.5864 26.6284 5.4805 27.1167 4.27879C27.5884 3.0769 27.8238 1.987 27.8238 1.00933C27.8238 0.945425 27.8203 0.865187 27.8115 0.768952C27.8041 0.672802 27.7998 0.592901 27.7998 0.528744C27.7834 0.480585 27.7632 0.392433 27.7389 0.264372C27.7146 0.136312 27.6944 0.0481595 27.6777 0C24.8139 0.657394 22.78 1.8432 21.5757 3.55799C20.3557 5.28879 19.7208 7.34786 19.6719 9.73563C20.7621 9.63939 21.6079 9.50342 22.2103 9.32703C23.0896 9.03849 23.9672 8.46134 24.8464 7.59598Z"
      fill="black"
    />
  </svg>
);

AppleIcon.defaultProps = {
  width: 40,
  height: 40,
};

AppleIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};

export default AppleIcon;
