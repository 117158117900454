import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';

export const Mail = ({ width, height, className, fill, outline }) =>
  outline ? (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      className={cls(className, 'icon icon-mail icon-mail-outline')}
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="mail_path-1-inside-1" fill="white">
        <path d="M1.99902 8.22104C1.99902 5.67301 4.06461 3.60742 6.61264 3.60742H17.3869C19.9349 3.60742 22.0005 5.67301 22.0005 8.22104V16.7786C22.0005 19.3266 19.9349 21.3922 17.3869 21.3922H6.61264C4.06461 21.3922 1.99902 19.3266 1.99902 16.7786V8.22104Z" />
      </mask>
      <path
        d="M6.61264 5.58469H17.3869V1.63016H6.61264V5.58469ZM20.0232 8.22104V16.7786H23.9778V8.22104H20.0232ZM17.3869 19.4149H6.61264V23.3695H17.3869V19.4149ZM3.97629 16.7786V8.22104H0.0217584V16.7786H3.97629ZM6.61264 19.4149C5.15662 19.4149 3.97629 18.2346 3.97629 16.7786H0.0217584C0.0217584 20.4186 2.9726 23.3695 6.61264 23.3695V19.4149ZM20.0232 16.7786C20.0232 18.2346 18.8429 19.4149 17.3869 19.4149V23.3695C21.0269 23.3695 23.9778 20.4186 23.9778 16.7786H20.0232ZM17.3869 5.58469C18.8429 5.58469 20.0232 6.76502 20.0232 8.22104H23.9778C23.9778 4.581 21.0269 1.63016 17.3869 1.63016V5.58469ZM6.61264 1.63016C2.9726 1.63016 0.0217584 4.58099 0.0217584 8.22104H3.97629C3.97629 6.76502 5.15662 5.58469 6.61264 5.58469V1.63016Z"
        fill={fill}
        mask="url(#mail_path-1-inside-1)"
      />
      <path
        d="M6.1197 8.32911C5.67205 8.1098 5.15 8.33736 4.95366 8.83739C4.75733 9.33742 4.96105 9.92055 5.4087 10.1399L11.6419 13.1936C11.6464 13.1959 11.651 13.1981 11.6556 13.2003C11.7681 13.2534 11.885 13.2785 12.0001 13.2784C12.1151 13.2785 12.2321 13.2534 12.3445 13.2003C12.3491 13.1981 12.3537 13.1959 12.3582 13.1936L18.5914 10.1399C19.0391 9.92055 19.2428 9.33742 19.0465 8.83739C18.8501 8.33736 18.3281 8.1098 17.8804 8.32911L12.58 10.9259C12.2141 11.1051 11.786 11.1051 11.4201 10.9259L6.1197 8.32911Z"
        fill={fill}
      />
    </svg>
  ) : (
    <svg
      width={width}
      height={height}
      className={cls(className, 'icon icon-mail icon-mail-bold')}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.62066 3.6084C4.06874 3.6084 2 5.67714 2 8.22906V16.7712C2 19.3231 4.06874 21.3919 6.62066 21.3919H17.3793C19.9313 21.3919 22 19.3231 22 16.7712V8.22906C22 5.67714 19.9313 3.6084 17.3793 3.6084H6.62066ZM4.95406 8.83628C5.15038 8.33629 5.67239 8.10874 6.12 8.32803L11.4191 10.9242C11.7855 11.1037 12.2144 11.1037 12.5808 10.9242L17.8799 8.32803C18.3275 8.10874 18.8495 8.33629 19.0458 8.83628C19.2421 9.33627 19.0384 9.91936 18.5908 10.1387L12.3581 13.1922L12.3444 13.1988C12.2319 13.2519 12.115 13.2771 11.9999 13.277C11.8849 13.2771 11.768 13.2519 11.6555 13.1988L11.6418 13.1922L5.40906 10.1387C4.96144 9.91936 4.75773 9.33627 4.95406 8.83628Z"
        fill={fill}
      />
    </svg>
  );

Mail.defaultProps = {
  width: 24,
  height: 24,
  fill: '#6E7DAE',
  outline: true,
};

Mail.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
  outline: PropTypes.bool,
};

export default Mail;
