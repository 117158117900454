import React from 'react'

const SelectDropdownCheckMark = (props) => (
  <svg width={props.width ? props.width : '24'}
       height={props.height ? props.height : '24'}
       fill={props.color ? props.color : '#FFFFFF'}
       className={props.className}
       viewBox="0 0 24 24"
       xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C20 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z"
    />
    <path
      d="M14.7012 8.39018L10.9212 13.3902L9.29117 11.2802C9.12806 11.0707 8.8884 10.9345 8.62491 10.9017C8.36142 10.8689 8.09569 10.9421 7.88617 11.1052C7.67664 11.2683 7.5405 11.5079 7.50768 11.7714C7.47486 12.0349 7.54806 12.3007 7.71117 12.5102L10.1412 15.6202C10.2353 15.7394 10.3554 15.8355 10.4923 15.9014C10.6292 15.9672 10.7793 16.001 10.9312 16.0002C11.0839 15.9998 11.2345 15.9645 11.3714 15.8969C11.5084 15.8293 11.628 15.7312 11.7212 15.6102L16.2912 9.61018C16.4529 9.398 16.5238 9.13025 16.4882 8.86583C16.4526 8.6014 16.3133 8.36196 16.1012 8.20018C15.889 8.03839 15.6212 7.96752 15.3568 8.00316C15.0924 8.03879 14.8529 8.178 14.6912 8.39018H14.7012Z"
    />
  </svg>
)

export default SelectDropdownCheckMark
