import React from 'react';
import cls from 'classname';

const Lock = ({ width, height, color, className, style, ...props }) => (
  <svg width={width ? width : '24'}
       height={height ? height : '24'}
       viewBox="0 0 24 24"
       fill={color ? color : '#F2F3F8'}
       style={style}
       className={cls(className, 'icon icon-lock')} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M8 6.55556C8 4.59188 9.79086 3 12 3C14.2091 3 16 4.59188 16 6.55556V10.5H14V6.55556C14 5.57372 13.1046 4.77778 12 4.77778C10.8954 4.77778 10 5.57372 10 6.55556V10.5H8V6.55556Z"
    />
    <path fillRule="evenodd" clipRule="evenodd"
          d="M9.7587 9H14.2413C15.0463 8.99999 15.7106 8.99998 16.2518 9.04079C16.8139 9.08319 17.3306 9.17417 17.816 9.40244C18.5686 9.75643 19.1805 10.3213 19.564 11.016C19.8113 11.464 19.9099 11.941 19.9558 12.4599C20 12.9594 20 13.5727 20 14.3157V15.6843C20 16.4273 20 17.0406 19.9558 17.5401C19.9099 18.059 19.8113 18.536 19.564 18.984C19.1805 19.6787 18.5686 20.2436 17.816 20.5976C17.3306 20.8258 16.8139 20.9168 16.2518 20.9592C15.7106 21 15.0463 21 14.2413 21H9.75873C8.95374 21 8.28938 21 7.74817 20.9592C7.18608 20.9168 6.66937 20.8258 6.18404 20.5976C5.43139 20.2436 4.81947 19.6787 4.43597 18.984C4.18868 18.536 4.09012 18.059 4.04419 17.5401C3.99998 17.0406 3.99999 16.4273 4 15.6843V14.3157C3.99999 13.5727 3.99998 12.9594 4.04419 12.4599C4.09012 11.941 4.18868 11.464 4.43597 11.016C4.81947 10.3213 5.43139 9.75643 6.18404 9.40244C6.66937 9.17417 7.18608 9.08319 7.74817 9.04079C8.28937 8.99998 8.95373 8.99999 9.7587 9ZM7.91104 10.8808C7.47262 10.9139 7.24842 10.9738 7.09202 11.0474C6.7157 11.2244 6.40973 11.5068 6.21799 11.8542C6.1383 11.9985 6.07337 12.2055 6.03755 12.6102C6.00078 13.0257 6 13.5632 6 14.3538V15.6462C6 16.4368 6.00078 16.9743 6.03755 17.3898C6.07337 17.7945 6.1383 18.0015 6.21799 18.1458C6.40973 18.4932 6.7157 18.7756 7.09202 18.9526C7.24842 19.0262 7.47262 19.0861 7.91104 19.1192C8.36113 19.1531 8.94342 19.1538 9.8 19.1538H14.2C15.0566 19.1538 15.6389 19.1531 16.089 19.1192C16.5274 19.0861 16.7516 19.0262 16.908 18.9526C17.2843 18.7756 17.5903 18.4932 17.782 18.1458C17.8617 18.0015 17.9266 17.7945 17.9624 17.3898C17.9992 16.9743 18 16.4368 18 15.6462V14.3538C18 13.5632 17.9992 13.0257 17.9624 12.6102C17.9266 12.2055 17.8617 11.9985 17.782 11.8542C17.5903 11.5068 17.2843 11.2244 16.908 11.0474C16.7516 10.9738 16.5274 10.9139 16.089 10.8808C15.6389 10.8469 15.0566 10.8462 14.2 10.8462H9.8C8.94342 10.8462 8.36113 10.8469 7.91104 10.8808Z"
    />
    <path
      d="M11 15.6667C11 15.2985 11.4477 15 12 15C12.5523 15 13 15.2985 13 15.6667V16.3333C13 16.7015 12.5523 17 12 17C11.4477 17 11 16.7015 11 16.3333V15.6667Z"
    />
  </svg>

);

export default Lock;
