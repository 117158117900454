import {put, select} from 'redux-saga/effects'
import {push} from 'connected-react-router'
import StartupActions from '../Redux/StartupRedux'
import {AuthSelectors} from '../Containers/LoginPage/reducer'
import AuthApi from '../Services/AuthApi'

import utils from '../Utils/utils'

const registrationPages = [
  /*'sign-up',
  'add-email',
  'restore-password',
  'check-mailbox',
  'expired',
  'already-registered',
  'verify-code',
  'success',*/
]
const innerPages = [
  /*'settings',*/
  'main',
  /*'success',*/
  'admin',
  'pixel_streaming',
]

// process STARTUP actions
export function* startup() {
  const userInfo = yield select(AuthSelectors.getUserInfo)
  const accessToken = yield select(AuthSelectors.getAccessToken)
  const refreshToken = yield select(AuthSelectors.getRefreshToken)
  const {pathname, search} = document.location

  if (accessToken && userInfo) {
    AuthApi.updateAccessToken(accessToken, refreshToken)
    if (utils.includeCurrentLocation(pathname, 'sign-up')) {
      yield put(push(pathname))
    } else if (pathname !== '/' && innerPages.some((el) => utils.includeCurrentLocation(pathname, el))) {
      yield put(push(search ? pathname + search : pathname))
    } else if (userInfo?.role?.name !== 'Admin') {
      yield put(push('/main'))
    } else if (userInfo?.role?.name === 'Admin') {
      yield put(push('/admin/customers'))
    }
  } else if (registrationPages.some((el) => utils.includeCurrentLocation(pathname, el))) {
    yield put(push(pathname))
  } else {
    yield put(push('/login'))
  }

  yield put(StartupActions.startupSuccess())
}
