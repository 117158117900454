import React from 'react';
import {Form} from "antd";

const UserForm = (props) => {
  const {onFinish, children} = props

  return (
    <Form onFinish={onFinish}>
      {children}
    </Form>
  );
};

export default UserForm;
