import React from 'react';
import PropTypes from 'prop-types';

export const PaperPlane = ({ width, height, className, fill }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Odacon/Outline/Share">
      <g id="Subtract">
        <mask
          id="paper-plane_path-1-outside-1"
          maskUnits="userSpaceOnUse"
          x="2.62463"
          y="3.12354"
          width="18"
          height="17"
          fill="black"
        >
          <rect fill="white" x="2.62463" y="3.12354" width="18" height="17" />
          <path d="M17.0612 5.13836C17.5462 5.06577 18.029 5.26313 18.3199 5.65611C18.6107 6.0491 18.6707 6.57004 18.4657 7.01337L13.762 17.4398C13.5371 17.9289 13.0688 18.2325 12.5321 18.2287C12.4771 18.2282 12.4162 18.2199 12.3622 18.2125C11.9024 18.15 11.5146 17.8616 11.3268 17.4203L9.94485 14.2221C9.69737 13.6494 9.87241 12.9817 10.369 12.604L14.5441 9.42915C14.8563 9.19173 14.9169 8.74615 14.6795 8.43393C14.4421 8.12171 13.9965 8.06108 13.6843 8.29851L9.51782 11.4669C9.01723 11.8475 8.32066 11.8343 7.83491 11.4349L5.16054 9.23587C4.75906 8.90682 4.51136 8.21689 4.67644 7.72241C4.83829 7.23649 5.2406 6.8914 5.75144 6.82163L17.0612 5.13836Z" />
        </mask>
        <path
          d="M17.0612 5.13836C17.5462 5.06577 18.029 5.26313 18.3199 5.65611C18.6107 6.0491 18.6707 6.57004 18.4657 7.01337L13.762 17.4398C13.5371 17.9289 13.0688 18.2325 12.5321 18.2287C12.4771 18.2282 12.4162 18.2199 12.3622 18.2125C11.9024 18.15 11.5146 17.8616 11.3268 17.4203L9.94485 14.2221C9.69737 13.6494 9.87241 12.9817 10.369 12.604L14.5441 9.42915C14.8563 9.19173 14.9169 8.74615 14.6795 8.43393C14.4421 8.12171 13.9965 8.06108 13.6843 8.29851L9.51782 11.4669C9.01723 11.8475 8.32066 11.8343 7.83491 11.4349L5.16054 9.23587C4.75906 8.90682 4.51136 8.21689 4.67644 7.72241C4.83829 7.23649 5.2406 6.8914 5.75144 6.82163L17.0612 5.13836Z"
          stroke="#6E7DAE"
          strokeWidth="3.5"
          mask="url(#paper-plane_path-1-outside-1)"
        />
      </g>
    </g>
  </svg>
);

PaperPlane.defaultProps = {
  width: 24,
  height: 24,
  fill: '#6E7DAE',
};

PaperPlane.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default PaperPlane;

