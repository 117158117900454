import { takeLatest, all, call } from 'redux-saga/effects';
import AuthAPI from './Services/AuthApi';

// sagas
import UserSagas from './Containers/User/saga';
import SettingSagas from './Containers/SettingPage/saga';
import DashboardSagas from './Containers/DashboardPage/saga';
import PortfolioAdminSagas from './Containers/UsersPageAdmin/saga';

/* ------------- Types ------------- */
import { StartupTypes } from './Redux/StartupRedux';
import { AuthTypes } from './Containers/LoginPage/reducer';

/* ------------- Sagas ------------- */

import { startup } from './Sagas/StartupSagas';

import {
  signIn,
  createUserPassword,
  checkEmail,
  changeEmail,
  confirmEmail,
  checkPassword,
  confirmPassword,
  logout,
  apiAuthSaga,
  getUserInfo,
} from './Containers/LoginPage/saga';

/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.
const authApi = AuthAPI;

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
  yield all([
    // some sagas only receive an action
    takeLatest(StartupTypes.STARTUP, startup),
    takeLatest(AuthTypes.SIGN_IN_REQUEST, signIn, authApi),
    takeLatest(AuthTypes.SIGN_UP_REQUEST, createUserPassword, authApi),
    takeLatest(AuthTypes.CHECK_EMAIL_REQUEST, checkEmail, authApi),
    takeLatest(AuthTypes.CHANGE_EMAIL_REQUEST, changeEmail, authApi),
    takeLatest(AuthTypes.CONFIRM_EMAIL_REQUEST, confirmEmail, authApi),
    takeLatest(AuthTypes.CHECK_PASSWORD_REQUEST, checkPassword, authApi),
    takeLatest(AuthTypes.CONFIRM_PASSWORD_REQUEST, confirmPassword, authApi),
    takeLatest(AuthTypes.LOGOUT, logout, authApi),
    takeLatest(AuthTypes.GET_USER_INFO_REQUEST, getUserInfo, authApi),
    call(apiAuthSaga),
    call(UserSagas),
    call(SettingSagas),
    call(DashboardSagas),
    call(PortfolioAdminSagas),
  ]);
}
