import React from 'react';
import cls from 'classname';
import PropTypes from 'prop-types';
import { Input } from 'antd';

// STYLES
import './styles.less';

export const InputComponent = ({
  placeholder,
  onChange,
  className,
  required,
  id,
  ...props
}) => (
  <Input
    id={id}
    required={required}
    placeholder={placeholder}
    onChange={onChange}
    className={cls(className, 'input')}
    {...props}
  />
);

InputComponent.defaultProps = {
  required: false
};

InputComponent.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  id: PropTypes.string.isRequired
};

export default InputComponent;
