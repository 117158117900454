import React, {useState} from 'react'
import {useTransition, animated} from 'react-spring'

import Images from '../../Images'

import './styles.less'
import Icon from '../../Icon'

const slides = [
  Images.slide1,
  Images.slide2,
  Images.slide3,
  Images.slide4,
]

function LoadingPage(props) {
  const [index, set] = useState(0)
  const transitions = useTransition(index, {
    key: index,
    from: {opacity: 0},
    enter: {opacity: 1},
    leave: {opacity: 0},
    config: {duration: 6000},
    onRest: (_a, _b, item) => {
      if (index === item) {
        set(state => (state + 1) % slides.length)
      }
    },
    exitBeforeEnter: true,
  })

  return (
    <div className="loader-page">
      {
        transitions((style, i) => (
          <animated.div
            className="bg-img"
            style={{
              ...style,
              backgroundImage: `url(${slides[i]})`,
            }}
          />
        ))
      }

      <div className="loader-page_wrap">
        <img src={Images.DTGlogo} alt="" className="logo"/>

        <div className="loader-page-notification">
          <div className="loader">
            <Icon
              type='spinner'
              role="icon"
            />
          </div>
          <div>
            <span className="sub-text">Loading Digital Hub</span>
            <span className="text">Please wait 3 min...</span>
          </div>

        </div>
      </div>

    </div>
  )
}

export default LoadingPage
