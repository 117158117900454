import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';

export const Notifications = ({ width, height, className, outline, fill }) =>
  outline ? (
    <svg
      width={width}
      height={height}
      className={cls(
        className,
        'icon icon-notification icon-notification-outline',
      )}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#6E7DAE"
    >
      <path
        d="M19.5841 14.9056L19.584 14.9056V14.9182C19.584 15.8736 18.8941 16.5055 18.2013 16.6611L18.1404 16.6748L18.0843 16.6957L18.0835 16.696L18.0833 16.6961L18.0829 16.6962L18.0714 16.7003C18.0591 16.7046 18.038 16.7119 18.0084 16.7217C17.9491 16.7413 17.8553 16.771 17.728 16.8075C17.4733 16.8806 17.0844 16.9809 16.5682 17.0822C15.536 17.2848 13.9947 17.4913 12 17.4913C10.0053 17.4913 8.46395 17.2848 7.43176 17.0822C6.91559 16.9809 6.52666 16.8806 6.27199 16.8075C6.14465 16.771 6.0509 16.7413 5.99161 16.7217C5.96196 16.7119 5.94093 16.7046 5.92863 16.7003L5.91715 16.6962L5.91674 16.6961L5.91647 16.696L5.91573 16.6957L5.90182 16.6905L5.8854 16.6851C5.06981 16.4155 4.41595 15.8206 4.41595 14.9182H4.41604L4.41587 14.9056C4.41207 14.6296 4.49791 14.1927 4.74689 13.8411C4.94751 13.5577 5.19669 13.2748 5.48895 12.9447L4.8033 12.3377L5.48895 12.9447L5.51177 12.9189C5.84912 12.5379 6.2472 12.0884 6.55489 11.5962C6.87731 11.0804 7.13983 10.4591 7.13983 9.72883C7.13983 6.32443 9.2734 4.3972 11.7612 4.3972H12.2388C14.7266 4.3972 16.8602 6.32443 16.8602 9.72883C16.8602 10.4591 17.1227 11.0805 17.4451 11.5962C17.7528 12.0884 18.1509 12.5379 18.4882 12.9189L18.511 12.9447C18.8033 13.2748 19.0525 13.5577 19.2531 13.8411C19.5021 14.1927 19.5879 14.6296 19.5841 14.9056Z"
        strokeWidth="1.83151"
      />
      <path
        d="M11.3575 20.6032C11.1073 20.6032 10.8833 20.491 10.733 20.3142H13.6958C13.5455 20.491 13.3215 20.6032 13.0712 20.6032H11.3575Z"
        strokeWidth="1.83151"
      />
    </svg>
  ) : (
    <svg
      width={width}
      height={height}
      className={cls(
        className,
        'icon icon-notification icon-notification-bold',
      )}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.20479 9.23951C5.20479 4.71821 8.1213 1.88965 11.719 1.88965H12.281C15.8787 1.88965 18.7952 4.71821 18.7952 9.23951C18.7952 10.4207 19.6363 11.3708 20.4667 12.3087C20.8024 12.6879 21.1364 13.0652 21.4123 13.4549C21.8627 14.091 22.0067 14.8406 21.9998 15.3446C21.9998 17.0576 20.7506 18.1725 19.5317 18.4463C19.5317 18.4463 16.8427 19.4492 12 19.4492C7.15732 19.4492 4.46826 18.4463 4.46826 18.4463C3.27519 18.052 2.00024 17.0576 2.00024 15.3446C1.9933 14.8406 2.1373 14.091 2.58765 13.4549C2.8636 13.0652 3.19758 12.6879 3.5333 12.3087C4.36371 11.3707 5.20479 10.4207 5.20479 9.23951Z" />
      <path d="M9.20299 21.0691C9.20299 20.8186 9.40607 20.6155 9.65658 20.6155H14.8478C15.0983 20.6155 15.3014 20.8186 15.3014 21.0691C15.3014 22.1964 14.3876 23.1102 13.2603 23.1102H11.2441C10.1168 23.1102 9.20299 22.1964 9.20299 21.0691Z" />
    </svg>
  );

Notifications.defaultProps = {
  width: 24,
  height: 24,
  // fill: '#6E7DAE',
  outline: true,
};

Notifications.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  // fill: PropTypes.string,
  outline: PropTypes.bool,
};

export default Notifications;
