import React from 'react'

const ArrowSliderNext = ({width, height, color, className, style, ...props}) => (
  <svg width={width ? width : '48'}
       height={height ? height : '48'}
       fill={color ? color : '#237CFB'}
       className={className}
       style={style}
       viewBox="0 0 48 48"
       xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.9996 37.9999C19.5323 38.0008 19.0794 37.838 18.7196 37.5399C18.5171 37.372 18.3497 37.1658 18.227 36.9331C18.1043 36.7004 18.0287 36.4458 18.0045 36.1838C17.9804 35.9219 18.0082 35.6577 18.0862 35.4065C18.1643 35.1553 18.2912 34.922 18.4596 34.7199L27.4196 23.9999L18.7796 13.2599C18.6135 13.0553 18.4894 12.8199 18.4146 12.5672C18.3397 12.3146 18.3155 12.0496 18.3434 11.7875C18.3713 11.5255 18.4507 11.2715 18.577 11.0402C18.7034 10.8089 18.8742 10.6049 19.0796 10.4399C19.2865 10.2578 19.5289 10.1205 19.7914 10.0365C20.0539 9.95252 20.331 9.92371 20.6052 9.95188C20.8793 9.98005 21.1448 10.0646 21.3847 10.2002C21.6247 10.3358 21.834 10.5195 21.9996 10.7399L31.6596 22.7399C31.9538 23.0977 32.1146 23.5466 32.1146 24.0099C32.1146 24.4731 31.9538 24.922 31.6596 25.2799L21.6596 37.2799C21.459 37.5219 21.2041 37.7132 20.9157 37.8383C20.6273 37.9634 20.3134 38.0188 19.9996 37.9999Z"
    />
  </svg>
)

export default ArrowSliderNext
