import React from 'react';

const ArrowBreadcrumb = ({ width, height, color, className, style, ...props }) => (
  <svg width={width ? width : '24'}
       height={height ? height : '24'}
       fill={color ? color : '#6E7DAF'}
       className={className}
       style={style}
       viewBox="0 0 24 24"
       xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4999 17.0002C10.3683 17.0009 10.2379 16.9757 10.116 16.9259C9.9942 16.8762 9.88338 16.8029 9.78994 16.7102C9.69621 16.6172 9.62182 16.5066 9.57105 16.3848C9.52028 16.2629 9.49414 16.1322 9.49414 16.0002C9.49414 15.8682 9.52028 15.7375 9.57105 15.6156C9.62182 15.4937 9.69621 15.3831 9.78994 15.2902L13.0999 12.0002L9.91994 8.69017C9.73369 8.50281 9.62915 8.24936 9.62915 7.98517C9.62915 7.72099 9.73369 7.46754 9.91994 7.28017C10.0129 7.18644 10.1235 7.11205 10.2454 7.06128C10.3672 7.01051 10.4979 6.98438 10.6299 6.98438C10.762 6.98438 10.8927 7.01051 11.0145 7.06128C11.1364 7.11205 11.247 7.18644 11.3399 7.28017L15.1999 11.2802C15.3832 11.4671 15.4858 11.7184 15.4858 11.9802C15.4858 12.2419 15.3832 12.4932 15.1999 12.6802L11.1999 16.6802C11.1102 16.7771 11.0021 16.8553 10.882 16.9102C10.7618 16.9651 10.632 16.9957 10.4999 17.0002Z"
     />
  </svg>
);

export default ArrowBreadcrumb;
