import logo from './logo.png';
import DTGlogo from './DTG_Logo.png';

import gmail from './to-gmail.svg';
import outlook from './toOutlook.svg';
import yahoo from './toYahoo.svg';
import protonmail from './toProtonmail.svg';
import aol from './toAol.svg';
import zoho from './toZoho.svg';
import icloud from './toICloud.svg';
import gmx from './toGMX.svg';
import hey from './toHey.svg';
import yandex from './toYandex.svg';
import google from './google.svg';
import facebook from './facebook.svg';
import apple from './apple.svg';
import email from './email.svg';
import lock from './lock.svg';
import person from './person.svg';

import edit from './edit.svg';
import check from './check.svg';

import defaultImg from './_default-img.svg';
import ResendEmailLoader from './resend-email-loader.svg';

import slide1 from './slide_1.jpg';
import slide2 from './slide_2.jpg';
import slide3 from './slide_3.jpg';
import slide4 from './slide_4.jpg';

const Images = {
  logo,
  DTGlogo,

  gmail,
  outlook,
  yahoo,
  protonmail,
  aol,
  zoho,
  icloud,
  gmx,
  hey,
  yandex,
  google,
  facebook,
  email,
  lock,
  person,
  apple,

  edit,
  check,

  defaultImg,
  ResendEmailLoader,

  slide1,
  slide2,
  slide3,
  slide4,
};

export default Images;
