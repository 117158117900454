import {createReducer, createActions} from 'reduxsauce';
import Immutable from 'seamless-immutable';
import openNotification from "../../Components/Notification";

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions(
  {
    getUsersRequest: ['payload'],
    getUsersSuccess: ['payload'], // payload: { data }
    getUsersFailure: ['payload'], // payload: { errors }
    getUsersRolesRequest: null,
    getUsersRolesSuccess: ['payload'],
    getUsersRolesFailure: ['payload'],
    getSelectedUserRequest: ['payload'],
    getSelectedUserSuccess: ['payload'],
    getSelectedUserFailure: ['payload'],
    updateUserRequest: ['payload'],
    updateUserSuccess: ['payload'],
    updateUserFailure: ['payload'],
    createUserRequest: ['payload'],
    createUserSuccess: ['payload'],
    createUserFailure: ['payload'],
    deleteUserRequest: ['payload'],
    deleteUserSuccess: ['payload'],
    deleteUserFailure: ['payload'],
    markAsDeletedUserRequest: ['payload'],
    markAsDeletedUserSuccess: ['payload'],
    markAsDeletedUserFailure: ['payload'],
    unmarkAsDeletedUserRequest: ['payload'],
    unmarkAsDeletedUserSuccess: ['payload'],
    unmarkAsDeletedUserFailure: ['payload'],
    getNextUsersRequest: ['payload'],
    getNextUsersSuccess: ['payload'],
    getNextUsersFailure: ['payload'],
    resetErrors: null
  },
);

export const UsersAdminTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  loading: false,
  errors: false,
  result: {},
  users_roles: [],
  selected_user: {},
});

/* ------------- Functions for reducer cases ------------- */

const getUsersRequest = (state) =>
  state.merge({loading: true});

const getUsersSuccess = (state, {payload: data}) =>
  state.merge({loading: false, result: data});

const getUsersFailure = (state, {payload: {errors}}) =>
  state.merge({loading: false, errors});

const getUsersRolesRequest = (state) =>
  state.merge({loading: true});

const getUsersRolesSuccess = (state, {payload: data}) =>
  state.merge({loading: false, users_roles: data});

const getUsersRolesFailure = (state, {payload: {errors}}) =>
  state.merge({loading: false, errors});

const getSelectedUserRequest = (state) =>
  state.merge({loading: true});

const getSelectedUserSuccess = (state, {payload: data}) =>
  state.merge({loading: false, selected_user: data});

const getSelectedUserFailure = (state, {payload: {errors}}) =>
  state.merge({loading: false, errors});

const updateUserRequest = (state) =>
  state.merge({loading: true});

const updateUserSuccess = (state, {payload: data}) => {
  const newResult = [...state.result.results].map(user => {
    if (user.id === data.id) {
      return data
    }
    return user
  })
  openNotification({
    type: 'info',
    message: `${data.first_name} ${data.last_name} has been successfully edited`
  })
  return state.merge({loading: false, result: {...state.result, results: newResult}});
}

const updateUserFailure = (state, {payload: {errors}}) =>
  state.merge({loading: false, errors});

const createUserRequest = (state) =>
  state.merge({loading: true});

const createUserSuccess = (state, {payload: data}) => {
  const newResult = [...state.result.results, data]
  const role = data.role.name.toLowerCase() === 'free access' ? 'user' : data.role.name
  openNotification({
    type: 'info',
    message: `The ${role} has been successfully created. Make sure that the ${role} completes the registration.`
  })
  return state.merge({loading: false, result: {...state.result, results: newResult}});
}

const createUserFailure = (state, {payload: {errors}}) =>
  state.merge({loading: false, errors});

const deleteUserRequest = (state) =>
  state.merge({loading: true});

const deleteUserSuccess = (state) => {
  const newResult = [...state.result.results].filter(user => user.id !== state.selected_user.id)
  openNotification({
    type: 'info',
    message: `The user has been successfully deleted`
  })
  return state.merge({loading: false, result: {...state.result, results: newResult}});
}

const deleteUserFailure = (state, {payload: {errors}}) =>
  state.merge({loading: false, errors});

const markAsDeletedUserRequest = (state) =>
  state.merge({loading: true});

const markAsDeletedUserSuccess = (state, {payload: data}) => {
  const newResult = [...state.result.results].map(user => {
    if (user.id === data.id) {
      return data
    }
    return user
  })
  openNotification({
    type: 'info',
    message: `The user has been successfully blocked`
  })
  return state.merge({loading: false, result: {...state.result, results: newResult}});
}

const markAsDeletedUserFailure = (state, {payload: {errors}}) =>
  state.merge({loading: false, errors});

const unmarkAsDeletedUserRequest = (state) =>
  state.merge({loading: true});

const unmarkAsDeletedUserSuccess = (state, {payload: data}) => {
  const newResult = [...state.result.results].map(user => {
    if (user.id === data.id) {
      return data
    }
    return user
  })
  openNotification({
    type: 'info',
    message: `The user has been successfully unblocked`
  })
  return state.merge({loading: false, result: {...state.result, results: newResult}});
}

const unmarkAsDeletedUserFailure = (state, {payload: {errors}}) =>
  state.merge({loading: false, errors});

const getNextUsersRequest = (state) =>
  state.merge({loading: true});

const getNextUsersSuccess = (state, {payload: data}) => {
  const newResult = [...state.result.results, ...data.results]
  return state.merge({
    loading: false, result:
      {...state.result, next: data.next, previous: data.previous, results: newResult}
  });
}

const getNextUsersFailure = (state, {payload: {errors}}) =>
  state.merge({loading: false, errors});

const resetErrors = (state) =>
  state.merge({loading: false, errors: false});
/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_USERS_REQUEST]: getUsersRequest,
  [Types.GET_USERS_SUCCESS]: getUsersSuccess,
  [Types.GET_USERS_FAILURE]: getUsersFailure,
  [Types.GET_USERS_ROLES_REQUEST]: getUsersRolesRequest,
  [Types.GET_USERS_ROLES_SUCCESS]: getUsersRolesSuccess,
  [Types.GET_USERS_ROLES_FAILURE]: getUsersRolesFailure,
  [Types.GET_SELECTED_USER_REQUEST]: getSelectedUserRequest,
  [Types.GET_SELECTED_USER_SUCCESS]: getSelectedUserSuccess,
  [Types.GET_SELECTED_USER_FAILURE]: getSelectedUserFailure,
  [Types.UPDATE_USER_REQUEST]: updateUserRequest,
  [Types.UPDATE_USER_SUCCESS]: updateUserSuccess,
  [Types.UPDATE_USER_FAILURE]: updateUserFailure,
  [Types.CREATE_USER_REQUEST]: createUserRequest,
  [Types.CREATE_USER_SUCCESS]: createUserSuccess,
  [Types.CREATE_USER_FAILURE]: createUserFailure,
  [Types.DELETE_USER_REQUEST]: deleteUserRequest,
  [Types.DELETE_USER_SUCCESS]: deleteUserSuccess,
  [Types.DELETE_USER_FAILURE]: deleteUserFailure,
  [Types.MARK_AS_DELETED_USER_REQUEST]: markAsDeletedUserRequest,
  [Types.MARK_AS_DELETED_USER_SUCCESS]: markAsDeletedUserSuccess,
  [Types.MARK_AS_DELETED_USER_FAILURE]: markAsDeletedUserFailure,
  [Types.UNMARK_AS_DELETED_USER_REQUEST]: unmarkAsDeletedUserRequest,
  [Types.UNMARK_AS_DELETED_USER_SUCCESS]: unmarkAsDeletedUserSuccess,
  [Types.UNMARK_AS_DELETED_USER_FAILURE]: unmarkAsDeletedUserFailure,
  [Types.GET_NEXT_USERS_REQUEST]: getNextUsersRequest,
  [Types.GET_NEXT_USERS_SUCCESS]: getNextUsersSuccess,
  [Types.GET_NEXT_USERS_FAILURE]: getNextUsersFailure,
  [Types.RESET_ERRORS]: resetErrors
});
