/* Admin Portfolio services */

import { API } from '../../Services/Api';

const request = new API();

export const getUsers = (q=null) => {
  const req = request.api.get(`/admin-panel/users/`,
    q);
  return req;
};

export const getUsersRoles = () => {
  const req = request.api.get(`/admin-panel/users/roles/`,
    null);
  return req;
};

export const getSelectedUser = (id) => {
  const req = request.api.get(`/admin-panel/users/${id}/`,
    null);
  return req;
};

export const updateUser = (data) => {
  const {id} = data
  const body = {...data}
  delete body.id
  const req = request.api.patch(`/admin-panel/users/${id}/`,
    body);
  return req;
};

export const createUser = (data) => {
  const req = request.api.post(`/admin-panel/users/`,
    data);
  return req;
};

export const deleteUser = (id) => {
  const req = request.api.delete(`/admin-panel/users/${id}/`,
    null);
  return req;
};

export const markAsDeletedUser = (id) => {
  const req = request.api.delete(`/admin-panel/users/${id}/delete/`,
    null);
  return req;
};

export const unmarkAsDeletedUser = (id) => {
  const req = request.api.post(`/admin-panel/users/${id}/delete/`,
    null);
  return req;
};

export const getNextUsers = (link) => {
  const req = request.api.get(link, null);
  return req
};
