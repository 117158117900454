import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';

const Pocket = ({ width, height, className, color }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cls(className, 'icon icon-pocket')}
  >
    <path
      d="M2.12667 2.88155C1 4.00822 1 5.82156 1 9.44825V10.5509C1 14.1776 1 15.9909 2.12667 17.1176C3.25333 18.2443 5.06668 18.2443 8.69336 18.2443H12.7626C16.3893 18.2443 18.2026 18.2443 19.3293 17.1176C19.8152 16.6317 20.0915 16.0182 20.2487 15.1865C20.3606 14.5943 20.4166 14.2981 20.2243 14.0661C20.032 13.8341 19.7015 13.8341 19.0405 13.8341H15.612C13.6646 13.8341 12.0859 12.2554 12.0859 10.308V9.69121C12.0859 7.74379 13.6646 6.16509 15.612 6.16509H19.0405C19.7015 6.16509 20.032 6.16509 20.2243 5.93306C20.4166 5.70103 20.3606 5.4049 20.2487 4.81265C20.0915 3.98101 19.8152 3.36742 19.3293 2.88155C18.2026 1.75488 16.3893 1.75488 12.7626 1.75488H8.69336C5.06668 1.75488 3.25333 1.75488 2.12667 2.88155Z"
      stroke={color}
      strokeWidth="1.92334"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.8136 8.27566C21.0014 8.46344 21.0014 8.76284 21.0014 9.36164L21.0014 9.44771V10.5504L21.0014 10.6364C21.0014 11.2353 21.0014 11.5347 20.8136 11.7224C20.6258 11.9102 20.3255 11.9102 19.7248 11.9102H15.9637C15.0432 11.9102 14.2969 11.1926 14.2969 10.3074V9.69067C14.2969 8.80548 15.0432 8.08789 15.9637 8.08789H19.7248C20.3255 8.08789 20.6258 8.08789 20.8136 8.27566ZM16.6818 10.9636C17.2153 10.9636 17.6477 10.5311 17.6477 9.99768C17.6477 9.46423 17.2153 9.03178 16.6818 9.03178C16.1484 9.03178 15.7159 9.46423 15.7159 9.99768C15.7159 10.5311 16.1484 10.9636 16.6818 10.9636Z"
      fill={color}
    />
  </svg>
);

Pocket.defaultProps = {
  width: 22,
  height: 20,
  color: '#6E7DAF',
};

Pocket.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  color: PropTypes.string,
};

export default Pocket;
