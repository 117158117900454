import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';

export const Moon = ({ width, height, className, fill, outline }) =>
  !outline ? (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      className={cls(className, 'icon icon-moon icon-moon-outline')}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.19982 14.6667H8.13316C7.21884 14.6597 6.3151 14.4704 5.47486 14.1098C4.63463 13.7492 3.87478 13.2246 3.23982 12.5667C2.06238 11.3075 1.39308 9.65687 1.36107 7.93327C1.32906 6.20968 1.93662 4.53532 3.06649 3.23332C3.79441 2.41755 4.70951 1.79071 5.73316 1.40666C5.8526 1.3607 5.98274 1.35009 6.10805 1.37609C6.23336 1.4021 6.34854 1.46363 6.43982 1.55332C6.52437 1.63954 6.58391 1.7471 6.61209 1.86451C6.64027 1.98193 6.63603 2.1048 6.59982 2.21999C6.23446 3.22061 6.16207 4.30469 6.39115 5.34501C6.62024 6.38532 7.14128 7.33872 7.89316 8.09332C8.65204 8.84289 9.6077 9.36217 10.6495 9.59106C11.6913 9.81995 12.7766 9.74908 13.7798 9.38666C13.8992 9.34463 14.0281 9.33746 14.1515 9.36599C14.2748 9.39453 14.3874 9.45759 14.4763 9.5478C14.5651 9.638 14.6264 9.75163 14.653 9.87539C14.6796 9.99915 14.6704 10.1279 14.6265 10.2467C14.2859 11.1558 13.7539 11.9811 13.0665 12.6667C12.427 13.3042 11.6679 13.8091 10.8327 14.1523C9.99755 14.4955 9.10278 14.6703 8.19982 14.6667ZM4.97316 3.27999C4.63827 3.51486 4.33178 3.78779 4.05982 4.09332C3.14285 5.14408 2.64877 6.49794 2.6734 7.89233C2.69804 9.28672 3.23963 10.6223 4.19316 11.64C4.70551 12.1721 5.31933 12.5961 5.99835 12.887C6.67737 13.1778 7.40781 13.3296 8.14649 13.3333H8.19982C9.08395 13.3328 9.95521 13.1215 10.7413 12.7168C11.5274 12.3122 12.2057 11.7259 12.7198 11.0067C11.6721 11.1424 10.6071 11.0362 9.60678 10.6963C8.60645 10.3563 7.69735 9.79153 6.9493 9.04542C6.20126 8.29931 5.63418 7.39167 5.29161 6.39222C4.94904 5.39278 4.8401 4.32811 4.97316 3.27999Z"
        fill="#6E7DAF"
      />
    </svg>
  ) : (
    <svg
      width={width}
      height={height}
      className={cls(className, 'icon icon-moon icon-moon-bold')}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.19982 14.6667H8.13316C7.21884 14.6597 6.3151 14.4704 5.47486 14.1098C4.63463 13.7492 3.87478 13.2246 3.23982 12.5667C2.06238 11.3075 1.39308 9.65688 1.36107 7.93329C1.32906 6.20969 1.93662 4.53533 3.06649 3.23334C3.79441 2.41757 4.70951 1.79073 5.73316 1.40667C5.8526 1.36071 5.98274 1.3501 6.10805 1.37611C6.23336 1.40212 6.34854 1.46364 6.43982 1.55334C6.52437 1.63955 6.58391 1.74711 6.61209 1.86453C6.64027 1.98195 6.63603 2.10481 6.59982 2.22001C6.23446 3.22063 6.16207 4.3047 6.39115 5.34502C6.62024 6.38534 7.14128 7.33874 7.89316 8.09334C8.65204 8.8429 9.6077 9.36219 10.6495 9.59108C11.6913 9.81997 12.7766 9.7491 13.7798 9.38667C13.8992 9.34464 14.0281 9.33747 14.1515 9.36601C14.2748 9.39454 14.3874 9.4576 14.4763 9.54781C14.5651 9.63802 14.6264 9.75164 14.653 9.8754C14.6796 9.99916 14.6704 10.1279 14.6265 10.2467C14.2859 11.1558 13.7539 11.9811 13.0665 12.6667C12.427 13.3042 11.6679 13.8091 10.8327 14.1523C9.99755 14.4955 9.10278 14.6703 8.19982 14.6667Z" />
    </svg>
  );

Moon.defaultProps = {
  width: 16,
  height: 16,
  fill: 'white',
  outline: true,
};

Moon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
  outline: PropTypes.bool,
};

export default Moon;
