import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';

import './styles.less';

export const Container = ({ className, children }) => (
  <div className={cls('container', className)}>{children}</div>
);

Container.defaultProps = {
  className: 'container',
};

Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
  ]),
};
