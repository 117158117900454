import React, { useState, useEffect } from 'react';

import { Form, Input, Button } from 'antd';

import cls from 'classname';

function LoginForm({ rememberMe, setRemember, onSubmit, error, ...props }) {

  const [errorText, setErrorText] = useState(null);
  const [isBackError, setIsBackError] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (error) {
      setIsBackError(true);
      if (typeof error === 'string') {
        setErrorText(error);
      } else {
        setErrorText(Object.values(error)[0]);
      }
    }
  }, [error]);

  const onFinish = values => {
    if (rememberMe) setRemember(values);
    onSubmit(values);
  };

  const onFinishFailed = ({ errorFields }) => {
    if (errorFields) setErrorText(errorFields[0]['errors']);
  };

  function handleClearError(e, field) {
    form.setFields([
      {
        name: field,
        errors: [],
      },
    ]);
    setErrorText(null);
    setIsBackError(false);
  }

  function onBlurHandler(e) {
    const target = e.target;

    setTimeout(() => {
      let error = form.getFieldError(target.name);
      if (error.length) {
        setErrorText(error[0]);
      }
    }, 10);
  }

  return (
    <Form onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          validateTrigger='onSubmit'
          className="login-form"
          layout="vertical"
          form={form}
    >
      <Form.Item noStyle shouldUpdate={true}>
        {() => <div className={cls('error-text', { 'error-text_empty': !errorText })}>{errorText}</div>}
      </Form.Item>

      <Form.Item label=""
                 name="email"
                 initialValue={rememberMe?.email}
                 validateStatus={errorText || isBackError ? 'error' : null}
                 rules={[
                   { type: 'email', message: 'The email or password you entered is incorrect.' },
                   { required: true, message: 'The email or password you entered is incorrect.' },
                 ]}
      >
        <Input
          placeholder={'Email Address'}
          name="email"
          type="email"
          onChange={e => handleClearError(e, 'email')}
          onBlur={onBlurHandler}
        />
      </Form.Item>
      <Form.Item label=""
                 name="password"
                 initialValue={rememberMe?.password}
                 validateStatus={errorText || isBackError ? 'error' : null}
                 rules={[{ required: true, message: 'The email or password you entered is incorrect.' }]}
      >
        <Input.Password
          size="large"
          placeholder={'Password'}
          autoComplete="new-password"
          visibilityToggle={false}
          onChange={e => handleClearError(e, 'password')}
        />
      </Form.Item>

      <Button type="primary" htmlType="submit" className="btn-login" style={{ width: '100%' }}>
        LOGIN
      </Button>

      {
        props.children
      }

    </Form>
  );
}

export default LoginForm;
