import {createReducer, createActions} from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  getInstanceRequest: null,
  getInstanceSuccess: ['payload'], // payload: { data }
  instanceFailure: ['payload'], // payload: { errors }
  instanceReset: null,
})

export const PixelStreamingTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  result: null,
  errors: false,
})

/* ------------- Functions for reducer cases ------------- */

const getInstanceRequest = (state) => (
  state.merge({fetching: true})
)

const getInstanceSuccess = (state, {payload: data}) => (
  state.merge({fetching: false, result: data})
)

const instanceFailure = (state, {payload: {errors}}) =>
  state.merge({fetching: false, errors})

const instanceReset = (state) =>
  state.merge({
    fetching: false,
    result: null,
    errors: false
  })


/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_INSTANCE_REQUEST]: getInstanceRequest,
  [Types.GET_INSTANCE_SUCCESS]: getInstanceSuccess,
  [Types.INSTANCE_FAILURE]: instanceFailure,
  [Types.INSTANCE_RESET]: instanceReset,
})
