import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import Iframe from 'react-iframe'
import PropTypes from 'prop-types'
import {Spin} from 'antd'

import Layout from '../Layout'

import './styles.less'

export const PixelStreamingPage = (
  {
    fetching,
    userInfo,
    isMobile,
    theme,
    match,
    pixelStreaming,
    ...props
  }) => {

  const {modelId} = match.params

  useEffect(() => {
    document.title = 'Main - MetaSOA'
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [])

  return (
    <Layout>
      <div className='pixel-streaming-page'>
        <Spin size="large"
              spinning={pixelStreaming.fetching}
          //delay={2000}
        >
          {
            pixelStreaming.result && modelId && <Iframe
            //url={`https://app.stateofart.dataforest.tech/`}
            //url={`https://${pixelStreaming.result}/${modelId}`}
            url={`https://${pixelStreaming.result}/`}
            width="100%"
            height="100%"
            //loading="loading..."
            id="myId"
            className="myClassname"
            display="initial"
            position="relative"/>
          }
        </Spin>
      </div>
    </Layout>
  )
}

PixelStreamingPage.defaultProps = {}

PixelStreamingPage.propTypes = {
  userInfo: PropTypes.object,
  isMobile: PropTypes.bool,
  theme: PropTypes.string,
  pixelStreaming: PropTypes.object,
}

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  isMobile: state.nav.isMobile,
  theme: state.nav.theme,
  pixelStreaming: state.pixelStreaming,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(PixelStreamingPage)
