/*Admin Portfolio sagas*/


import { takeLatest, put, call } from 'redux-saga/effects';

import * as services from './services';
import Creators, { UsersAdminTypes as constants } from './reducer';

const actions = {
  getUsersActions: {
    request: Creators.getUsersRequest,
    success: Creators.getUsersSuccess,
    errors: Creators.getUsersFailure,
  },
  getUsersRolesActions: {
    request: Creators.getUsersRolesRequest,
    success: Creators.getUsersRolesSuccess,
    errors: Creators.getUsersRolesFailure,
  },
  getSelectedUserActions: {
    request: Creators.getSelectedUserRequest,
    success: Creators.getSelectedUserSuccess,
    errors: Creators.getSelectedUserFailure,
  },
  updateUserActions: {
    request: Creators.updateUserRequest,
    success: Creators.updateUserSuccess,
    errors: Creators.updateUserFailure,
  },
  createUserActions: {
    request: Creators.createUserRequest,
    success: Creators.createUserSuccess,
    errors: Creators.createUserFailure,
  },
  deleteUserActions: {
    request: Creators.deleteUserRequest,
    success: Creators.deleteUserSuccess,
    errors: Creators.deleteUserFailure,
  },
  markAsDeletedUserActions: {
    request: Creators.markAsDeletedUserRequest,
    success: Creators.markAsDeletedUserSuccess,
    errors: Creators.markAsDeletedUserFailure,
  },
  unmarkAsDeletedUserActions: {
    request: Creators.unnmarkAsDeletedUserRequest,
    success: Creators.unmarkAsDeletedUserSuccess,
    errors: Creators.unmarkAsDeletedUserFailure,
  },
  getNextUsersActions: {
    request: Creators.getNextUsersRequest,
    success: Creators.getNextUsersSuccess,
    errors: Creators.getNextUsersFailure,
  },
};

const eventsOptions = {
  [constants.GET_USERS_REQUEST]: {
    api: services.getUsers,
    actions: actions.getUsersActions,
  },
  [constants.GET_USERS_ROLES_REQUEST]: {
    api: services.getUsersRoles,
    actions: actions.getUsersRolesActions,
  },
  [constants.GET_SELECTED_USER_REQUEST]: {
    api: services.getSelectedUser,
    actions: actions.getSelectedUserActions,
  },
  [constants.UPDATE_USER_REQUEST]: {
    api: services.updateUser,
    actions: actions.updateUserActions,
  },
  [constants.CREATE_USER_REQUEST]: {
    api: services.createUser,
    actions: actions.createUserActions,
  },
  [constants.DELETE_USER_REQUEST]: {
    api: services.deleteUser,
    actions: actions.deleteUserActions,
  },
  [constants.MARK_AS_DELETED_USER_REQUEST]: {
    api: services.markAsDeletedUser,
    actions: actions.markAsDeletedUserActions,
  },
  [constants.UNMARK_AS_DELETED_USER_REQUEST]: {
    api: services.unmarkAsDeletedUser,
    actions: actions.unmarkAsDeletedUserActions,
  },
  [constants.GET_NEXT_USERS_REQUEST]: {
    api: services.getNextUsers,
    actions: actions.getNextUsersActions,
  }
};

// const events = Object.keys(eventsOptions);

function* apiGenerator(action) {
  const provider = eventsOptions[action.type];

  try {
    const params = action.payload;
    const response = yield call(provider.api, params);
    if (action.type === 'DELETE_USER_REQUEST') {
      yield put(provider.actions.success());
    } else if (response.data && response.status !== 400) {
      yield put(provider.actions.success(response.data));
    } else {
      yield put(provider.actions.errors({ errors: response.data }));
    }
  } catch (errors) {
    yield put(provider.actions.errors({ errors }));
  }
}

export default function* apiSaga() {
  yield takeLatest(constants.GET_USERS_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_USERS_ROLES_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_SELECTED_USER_REQUEST, apiGenerator);
  yield takeLatest(constants.UPDATE_USER_REQUEST, apiGenerator);
  yield takeLatest(constants.CREATE_USER_REQUEST, apiGenerator);
  yield takeLatest(constants.DELETE_USER_REQUEST, apiGenerator);
  yield takeLatest(constants.MARK_AS_DELETED_USER_REQUEST, apiGenerator);
  yield takeLatest(constants.UNMARK_AS_DELETED_USER_REQUEST, apiGenerator);
  yield takeLatest(constants.GET_NEXT_USERS_REQUEST, apiGenerator);
}
