import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { history } from './Utils/utils';
import { reducer as auth } from './Containers/LoginPage/reducer';
import { reducer as startup } from './Redux/StartupRedux';
import { reducer as nav } from './Redux/NavRedux';
import { reducer as errors } from './Redux/ErrorsRedux';

import { reducer as users } from './Containers/User/reducer';
import { reducer as setting } from './Containers/SettingPage/reducer';
import { reducer as dashboard } from './Containers/DashboardPage/reducer';
import { reducer as pixelStreaming } from './Containers/PixelStreaming/reducer';

import { reducer as portfolioAdmin } from './Containers/UsersPageAdmin/reducer';


export const reducers = combineReducers({
  auth,
  nav,
  errors,
  startup,

  users,
  setting,
  dashboard,
  pixelStreaming,

  portfolioAdmin,

  router: connectRouter(history),
});

export default reducers;
