import apisauce, { CancelToken } from 'apisauce';

export class API {
  constructor() {
    this.baseURL = process.env.REACT_APP_BASE_API_ROUTE;

    if (!API.instance) {
      this.create();
      this.createInterceptor();

      API.instance = this;
    }

    return API.instance;
  }

  create() {
    this.api = apisauce.create({
      // base URL is read from the 'constructor'
      baseURL: this.baseURL,
      // here are some default headers
      headers: {
        'Content-Type': 'application/json',
      },
      cancelToken: new CancelToken((cancel) => {
        this.source = cancel;
      }),
      // 300 second timeout...
      timeout: 300000,
    });
  }

  cancelAllRequest() {
    API.instance.source();
  }

  createInterceptor() {
    this.interceptors = this.api.axiosInstance.interceptors;
  }
}

const create = (baseURL = process.env.REACT_APP_BASE_API_ROUTE) => {
  // ------
  // STEP 1
  // ------
  //
  // Create and configure an apisauce-based api object.
  //
  const accessData = { accessToken: null };

  const api = apisauce.create({
    // base URL is read from the 'constructor'
    baseURL,
    // here are some default headers
    headers: {
      'Content-Type': 'application/json',
    },
    // 60 second timeout...
    timeout: 60000,
  });

  // ------
  // STEP 2
  // ------
  //
  // Define some functions that call the api.  The goal is to provide
  // a thin wrapper of the api layer providing nicer feeling functions
  // rather than 'get', 'post' and friends.
  //
  // I generally don't like wrapping the output at this level because
  // sometimes specific actions need to be take on `403` or `401`, etc.
  //
  // Since we can't hide from that, we embrace it by getting out of the
  // way at this level.
  //

  const updateAccessToken = (accessToken, refreshToken) => {
    accessData.accessToken = accessToken;
    accessData.refreshToken = refreshToken;

    if (accessToken) {
      api.setHeader('Authorization', `JWT ${accessToken}`);
    } else {
      api.setHeader('Authorization', null);
    }
  };

  // ------
  // STEP 3
  // ------
  //
  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  // methods in step 2.
  //
  // Notice we're not returning back the `api` created in step 1?  That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //
  return {
    api,
    // a list of the API functions from step 2
    updateAccessToken,
  };
};

// let's return back our create method as the default.
export default create();
