import React from 'react';
import cls from 'classname';

const ArrowAdd = ({ width, height, color, className, style, ...props }) => (
  <svg width={width ? width : '24'}
       height={height ? height : '24'}
       fill={color ? color : '#FFFFFF'}
       className={cls(className, 'icon')}
       style={style} viewBox="0 0 24 24"
       xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.78 12.38L15.78 7.38C15.608 7.19782 15.3739 7.08682 15.1241 7.06897C14.8742 7.05112 14.6267 7.12771 14.4306 7.2836C14.2344 7.43949 14.104 7.66332 14.065 7.91079C14.026 8.15827 14.0813 8.41136 14.22 8.62L16.92 12H8C7.73478 12 7.48043 11.8946 7.29289 11.7071C7.10536 11.5196 7 11.2652 7 11V6C7 5.73478 6.89464 5.48043 6.70711 5.29289C6.51957 5.10536 6.26522 5 6 5C5.73478 5 5.48043 5.10536 5.29289 5.29289C5.10536 5.48043 5 5.73478 5 6V11C5 11.7956 5.31607 12.5587 5.87868 13.1213C6.44129 13.6839 7.20435 14 8 14H16.92L14.22 17.38C14.0565 17.5872 13.9816 17.8507 14.0115 18.113C14.0415 18.3752 14.174 18.615 14.38 18.78C14.5559 18.9212 14.7744 18.9987 15 19C15.1502 18.9993 15.2983 18.9648 15.4334 18.899C15.5684 18.8332 15.6869 18.7379 15.78 18.62L19.78 13.62C19.9195 13.4435 19.9954 13.225 19.9954 13C19.9954 12.775 19.9195 12.5565 19.78 12.38Z"
    />
  </svg>

);

export default ArrowAdd;
