import React from 'react';
import './styles.less'
import {Table} from "antd";

function UsersAdminTable({data, columns, onSort, reference, loading}) {

  return (
    <div className={'table__wrapper'}>
      <Table components={{
        table: ({className, children, ...other}) => (
          <table
            {...other}
            //pass tableRed to tbody to attach scroll listener
            ref={reference}>
            {children}
          </table>
        ),
      }}
        //change default text if table is empty
             locale={{emptyText: 'Not found. Try another search query'}}
             loading={loading}
             dataSource={data}
        //fix header
             sticky={true}
        //disable native pagination(pages)
             pagination={false}
             columns={columns}
        //set unique react key for every tr
             rowKey={record => record.id}
        //table height
             scroll={{x: 1300, y: 1104}}
             style={{height: '1104px'}}
        //antd table function for sorting (parent handleSort)
             onChange={(pagination, filters, sorter, extra) => onSort(sorter)}
      />
    </div>
  );
}

export default UsersAdminTable;
