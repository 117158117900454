import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';

export const User = ({ width, height, className, fill, outline }) =>
  outline ? (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      xmlns="http://www.w3.org/2000/svg"
      className={cls(className, 'icon icon-user icon-user-outline')}
    >
      <mask id="icon-user-outline" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0007 11.9939C14.5967 11.9939 16.7012 9.88936 16.7012 7.29332C16.7012 4.69728 14.5967 2.59277 12.0007 2.59277C9.40462 2.59277 7.30011 4.69728 7.30011 7.29332C7.30011 9.88936 9.40462 11.9939 12.0007 11.9939ZM11.9995 22.4072C7.3059 22.4072 3.50098 21.2413 3.50098 18.828C3.50098 16.4147 7.3059 13.6678 11.9995 13.6678C16.6931 13.6678 20.4985 16.4147 20.4985 18.828C20.4985 21.2413 16.6931 22.4072 11.9995 22.4072Z"
        />
      </mask>
      <path
        d="M11.9995 13.6678V15.868V15.868V13.6678ZM14.501 7.29332C14.501 8.67425 13.3816 9.79371 12.0007 9.79371V14.194C15.8118 14.194 18.9014 11.1045 18.9014 7.29332H14.501ZM12.0007 4.79293C13.3816 4.79293 14.501 5.9124 14.501 7.29332H18.9014C18.9014 3.48216 15.8118 0.392612 12.0007 0.392612V4.79293ZM9.50027 7.29332C9.50027 5.9124 10.6197 4.79293 12.0007 4.79293V0.392612C8.1895 0.392612 5.09995 3.48217 5.09995 7.29332H9.50027ZM12.0007 9.79371C10.6197 9.79371 9.50027 8.67425 9.50027 7.29332H5.09995C5.09995 11.1045 8.1895 14.194 12.0007 14.194V9.79371ZM1.30082 18.828C1.30082 21.4525 3.41402 22.8872 5.19845 23.5754C7.08933 24.3046 9.51579 24.6074 11.9995 24.6074V20.207C9.78964 20.207 7.96683 19.9268 6.78184 19.4698C6.19187 19.2423 5.8881 19.0195 5.7572 18.8829C5.69957 18.8228 5.6904 18.7952 5.69434 18.8043C5.70018 18.8179 5.70114 18.832 5.70114 18.828H1.30082ZM11.9995 11.4676C9.24018 11.4676 6.7011 12.2707 4.80063 13.492C3.022 14.6351 1.30082 16.5048 1.30082 18.828H5.70114C5.70114 18.7379 5.88242 18.0275 7.17965 17.1938C8.35506 16.4384 10.0653 15.868 11.9995 15.868V11.4676ZM22.6987 18.828C22.6987 16.5047 20.9773 14.635 19.1986 13.492C17.298 12.2707 14.7589 11.4676 11.9995 11.4676V15.868C13.9338 15.868 15.6441 16.4384 16.8197 17.1938C18.1171 18.0276 18.2984 18.738 18.2984 18.828H22.6987ZM11.9995 24.6074C14.4833 24.6074 16.9098 24.3046 18.8008 23.5754C20.5852 22.8873 22.6987 21.4526 22.6987 18.828H18.2984C18.2984 18.832 18.2993 18.8179 18.3052 18.8043C18.3091 18.7951 18.3 18.8227 18.2423 18.8829C18.1114 19.0194 17.8075 19.2422 17.2175 19.4698C16.0324 19.9268 14.2094 20.207 11.9995 20.207V24.6074Z"
        fill={fill}
        mask="url(#icon-user-outline)"
      />
    </svg>
  ) : (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      className={cls(className, 'icon icon-user icon-user-bold')}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0001 11.9933C14.5965 11.9933 16.7013 9.88846 16.7013 7.29204C16.7013 4.69563 14.5965 2.59082 12.0001 2.59082C9.40371 2.59082 7.2989 4.69563 7.2989 7.29204C7.2989 9.88846 9.40371 11.9933 12.0001 11.9933ZM11.9998 22.4087C7.30547 22.4087 3.5 21.2427 3.5 18.829C3.5 16.4154 7.30547 13.6681 11.9998 13.6681C16.6941 13.6681 20.5 16.4154 20.5 18.829C20.5 21.2427 16.6941 22.4087 11.9998 22.4087Z"
        fill={fill}
      />
    </svg>
  );

User.defaultProps = {
  width: 24,
  height: 24,
  fill: '#6E7DAE',
  outline: true,
};

User.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
  outline: PropTypes.bool,
};

export default User;
