import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';

export const Sun = ({ width, height, className, outline /* , fill */ }) =>
  !outline ? (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      className={cls(className, 'icon icon-sun icon-sun-outline')}
    >
      <path
        d="M8.00004 3.99992C8.17685 3.99992 8.34642 3.92968 8.47145 3.80466C8.59647 3.67963 8.66671 3.51006 8.66671 3.33325V1.99992C8.66671 1.82311 8.59647 1.65354 8.47145 1.52851C8.34642 1.40349 8.17685 1.33325 8.00004 1.33325C7.82323 1.33325 7.65366 1.40349 7.52864 1.52851C7.40361 1.65354 7.33337 1.82311 7.33337 1.99992V3.33325C7.33337 3.51006 7.40361 3.67963 7.52864 3.80466C7.65366 3.92968 7.82323 3.99992 8.00004 3.99992Z"
        // fill={fill || '#0856C6'}
      />
      <path
        d="M14 7.33325H12.6667C12.4899 7.33325 12.3203 7.40349 12.1953 7.52851C12.0702 7.65354 12 7.82311 12 7.99992C12 8.17673 12.0702 8.3463 12.1953 8.47132C12.3203 8.59635 12.4899 8.66659 12.6667 8.66659H14C14.1768 8.66659 14.3464 8.59635 14.4714 8.47132C14.5964 8.3463 14.6667 8.17673 14.6667 7.99992C14.6667 7.82311 14.5964 7.65354 14.4714 7.52851C14.3464 7.40349 14.1768 7.33325 14 7.33325Z"
        // fill={fill || '#0856C6'}
      />
      <path
        d="M4.00004 7.99992C4.00004 7.82311 3.9298 7.65354 3.80478 7.52851C3.67975 7.40349 3.51019 7.33325 3.33337 7.33325H2.00004C1.82323 7.33325 1.65366 7.40349 1.52864 7.52851C1.40361 7.65354 1.33337 7.82311 1.33337 7.99992C1.33337 8.17673 1.40361 8.3463 1.52864 8.47132C1.65366 8.59635 1.82323 8.66659 2.00004 8.66659H3.33337C3.51019 8.66659 3.67975 8.59635 3.80478 8.47132C3.9298 8.3463 4.00004 8.17673 4.00004 7.99992Z"
        // fill={fill || '#0856C6'}
      />
      <path
        d="M4.14662 3.3333C4.01666 3.21041 3.84321 3.14419 3.66443 3.14919C3.48564 3.15419 3.31617 3.23001 3.19328 3.35996C3.0704 3.48992 3.00417 3.66337 3.00918 3.84215C3.01418 4.02094 3.08999 4.19041 3.21995 4.3133L4.17995 5.23996C4.24437 5.30217 4.32067 5.35076 4.40428 5.38283C4.4879 5.4149 4.57712 5.4298 4.66662 5.42663C4.75642 5.42629 4.84523 5.40781 4.92771 5.37229C5.0102 5.33678 5.08465 5.28497 5.14662 5.21996C5.27078 5.09506 5.34048 4.92609 5.34048 4.74996C5.34048 4.57384 5.27078 4.40487 5.14662 4.27996L4.14662 3.3333Z"
        // fill={fill || '#0856C6'}
      />
      <path
        d="M11.3333 5.42674C11.505 5.42606 11.6697 5.3592 11.7933 5.24008L12.7533 4.31341C12.8704 4.19104 12.9366 4.02869 12.9383 3.85932C12.9401 3.68995 12.8773 3.52627 12.7627 3.40152C12.6481 3.27677 12.4904 3.20031 12.3215 3.18765C12.1526 3.175 11.9852 3.22712 11.8533 3.33341L10.8933 4.28008C10.7692 4.40498 10.6995 4.57395 10.6995 4.75008C10.6995 4.9262 10.7692 5.09517 10.8933 5.22008C11.0088 5.34189 11.1659 5.41569 11.3333 5.42674Z"
        // fill={fill || '#0856C6'}
      />
      <path
        d="M8.00004 12C7.82323 12 7.65366 12.0702 7.52864 12.1953C7.40361 12.3203 7.33337 12.4899 7.33337 12.6667V14C7.33337 14.1768 7.40361 14.3464 7.52864 14.4714C7.65366 14.5964 7.82323 14.6667 8.00004 14.6667C8.17685 14.6667 8.34642 14.5964 8.47145 14.4714C8.59647 14.3464 8.66671 14.1768 8.66671 14V12.6667C8.66671 12.4899 8.59647 12.3203 8.47145 12.1953C8.34642 12.0702 8.17685 12 8.00004 12Z"
        // fill={fill || '#0856C6'}
      />
      <path
        d="M11.82 10.7599C11.6927 10.637 11.5218 10.5697 11.3449 10.5729C11.168 10.576 10.9996 10.6493 10.8767 10.7766C10.7538 10.9039 10.6866 11.0748 10.6897 11.2517C10.6928 11.4286 10.7661 11.597 10.8934 11.7199L11.8534 12.6666C11.977 12.7857 12.1417 12.8525 12.3134 12.8532C12.4027 12.8538 12.4912 12.8363 12.5737 12.802C12.6561 12.7676 12.7309 12.717 12.7934 12.6532C12.8559 12.5913 12.9055 12.5175 12.9393 12.4363C12.9732 12.355 12.9906 12.2679 12.9906 12.1799C12.9906 12.0919 12.9732 12.0048 12.9393 11.9235C12.9055 11.8423 12.8559 11.7685 12.7934 11.7066L11.82 10.7599Z"
        // fill={fill || '#0856C6'}
      />
      <path
        d="M4.18003 10.7599L3.22003 11.6866C3.15754 11.7486 3.10794 11.8223 3.0741 11.9035C3.04025 11.9848 3.02283 12.0719 3.02283 12.1599C3.02283 12.2479 3.04025 12.3351 3.0741 12.4163C3.10794 12.4975 3.15754 12.5713 3.22003 12.6332C3.28255 12.697 3.35727 12.7476 3.43972 12.782C3.52218 12.8163 3.6107 12.8338 3.70003 12.8332C3.86437 12.8346 4.02344 12.7753 4.14669 12.6666L5.10669 11.7399C5.234 11.617 5.30727 11.4486 5.3104 11.2717C5.31352 11.0948 5.24624 10.9239 5.12336 10.7966C5.00048 10.6693 4.83205 10.596 4.65514 10.5929C4.47824 10.5898 4.30733 10.657 4.18003 10.7799V10.7599Z"
        // fill={fill || '#0856C6'}
      />
      <path
        d="M8.00004 5.33325C7.47263 5.33325 6.95705 5.48965 6.51852 5.78267C6.07999 6.07568 5.7382 6.49216 5.53636 6.97943C5.33453 7.4667 5.28172 8.00288 5.38461 8.52016C5.48751 9.03744 5.74148 9.5126 6.11442 9.88554C6.48736 10.2585 6.96252 10.5125 7.4798 10.6153C7.99708 10.7182 8.53326 10.6654 9.02053 10.4636C9.5078 10.2618 9.92428 9.91997 10.2173 9.48144C10.5103 9.04291 10.6667 8.52734 10.6667 7.99992C10.6667 7.29267 10.3858 6.6144 9.88566 6.1143C9.38556 5.6142 8.70729 5.33325 8.00004 5.33325ZM8.00004 9.33325C7.73633 9.33325 7.47855 9.25505 7.25928 9.10854C7.04002 8.96204 6.86912 8.7538 6.7682 8.51016C6.66729 8.26653 6.64088 7.99844 6.69233 7.7398C6.74378 7.48116 6.87076 7.24358 7.05723 7.05711C7.2437 6.87064 7.48128 6.74365 7.73992 6.6922C7.99856 6.64076 8.26665 6.66716 8.51029 6.76808C8.75392 6.869 8.96216 7.03989 9.10867 7.25916C9.25518 7.47842 9.33338 7.73621 9.33338 7.99992C9.33338 8.35354 9.1929 8.69268 8.94285 8.94273C8.6928 9.19278 8.35366 9.33325 8.00004 9.33325Z"
        // fill={fill || '#0856C6'}
      />
    </svg>
  ) : (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      className={cls(className, 'icon-sun-bold')}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00016 3.99999C8.17697 3.99999 8.34654 3.92976 8.47157 3.80473C8.59659 3.67971 8.66683 3.51014 8.66683 3.33333V1.99999C8.66683 1.82318 8.59659 1.65361 8.47157 1.52859C8.34654 1.40357 8.17697 1.33333 8.00016 1.33333C7.82335 1.33333 7.65378 1.40357 7.52876 1.52859C7.40373 1.65361 7.3335 1.82318 7.3335 1.99999V3.33333C7.3335 3.51014 7.40373 3.67971 7.52876 3.80473C7.65378 3.92976 7.82335 3.99999 8.00016 3.99999Z"
        // fill={fill || 'white'}
      />
      <path
        d="M14 7.33333H12.6667C12.4899 7.33333 12.3203 7.40357 12.1953 7.52859C12.0702 7.65361 12 7.82318 12 7.99999C12 8.17681 12.0702 8.34638 12.1953 8.4714C12.3203 8.59642 12.4899 8.66666 12.6667 8.66666H14C14.1768 8.66666 14.3464 8.59642 14.4714 8.4714C14.5964 8.34638 14.6667 8.17681 14.6667 7.99999C14.6667 7.82318 14.5964 7.65361 14.4714 7.52859C14.3464 7.40357 14.1768 7.33333 14 7.33333Z"
        // fill={fill || 'white'}
      />
      <path
        d="M4.00016 7.99999C4.00016 7.82318 3.92992 7.65361 3.8049 7.52859C3.67988 7.40357 3.51031 7.33333 3.3335 7.33333H2.00016C1.82335 7.33333 1.65378 7.40357 1.52876 7.52859C1.40373 7.65361 1.3335 7.82318 1.3335 7.99999C1.3335 8.17681 1.40373 8.34638 1.52876 8.4714C1.65378 8.59642 1.82335 8.66666 2.00016 8.66666H3.3335C3.51031 8.66666 3.67988 8.59642 3.8049 8.4714C3.92992 8.34638 4.00016 8.17681 4.00016 7.99999Z"
        // fill={fill || 'white'}
      />
      <path
        d="M4.14649 3.33333C4.01654 3.21045 3.84309 3.14422 3.66431 3.14922C3.48552 3.15422 3.31605 3.23004 3.19316 3.36C3.07028 3.48995 3.00405 3.6634 3.00905 3.84218C3.01405 4.02097 3.08987 4.19044 3.21983 4.31333L4.17983 5.24C4.24425 5.3022 4.32055 5.35079 4.40416 5.38286C4.48778 5.41493 4.577 5.42983 4.6665 5.42666C4.7563 5.42632 4.84511 5.40784 4.92759 5.37232C5.01007 5.33681 5.08453 5.285 5.1465 5.22C5.27066 5.09509 5.34036 4.92612 5.34036 4.75C5.34036 4.57387 5.27066 4.4049 5.1465 4.28L4.14649 3.33333Z"
        // fill={fill || 'white'}
      />
      <path
        d="M11.3331 5.42667C11.5047 5.42598 11.6695 5.35912 11.7931 5.24L12.7531 4.31333C12.8702 4.19097 12.9363 4.02861 12.9381 3.85925C12.9398 3.68988 12.877 3.5262 12.7625 3.40145C12.6479 3.2767 12.4901 3.20023 12.3212 3.18758C12.1523 3.17493 11.985 3.22704 11.8531 3.33333L10.8931 4.28C10.7689 4.40491 10.6992 4.57388 10.6992 4.75C10.6992 4.92612 10.7689 5.09509 10.8931 5.22C11.0085 5.34181 11.1656 5.41561 11.3331 5.42667Z"
        // fill={fill || 'white'}
      />
      <path
        d="M8.00016 12C7.82335 12 7.65378 12.0702 7.52876 12.1953C7.40373 12.3203 7.3335 12.4899 7.3335 12.6667V14C7.3335 14.1768 7.40373 14.3464 7.52876 14.4714C7.65378 14.5964 7.82335 14.6667 8.00016 14.6667C8.17697 14.6667 8.34654 14.5964 8.47157 14.4714C8.59659 14.3464 8.66683 14.1768 8.66683 14V12.6667C8.66683 12.4899 8.59659 12.3203 8.47157 12.1953C8.34654 12.0702 8.17697 12 8.00016 12Z"
        // fill={fill || 'white'}
      />
      <path
        d="M11.8199 10.76C11.6926 10.6371 11.5217 10.5698 11.3448 10.5729C11.1679 10.5761 10.9995 10.6494 10.8766 10.7767C10.7537 10.904 10.6864 11.0749 10.6896 11.2518C10.6927 11.4287 10.766 11.5971 10.8933 11.72L11.8533 12.6667C11.9768 12.7858 12.1416 12.8526 12.3133 12.8533C12.4026 12.8538 12.4911 12.8364 12.5736 12.8021C12.656 12.7677 12.7307 12.7171 12.7933 12.6533C12.8557 12.5913 12.9053 12.5176 12.9392 12.4364C12.973 12.3551 12.9905 12.268 12.9905 12.18C12.9905 12.092 12.973 12.0048 12.9392 11.9236C12.9053 11.8424 12.8557 11.7686 12.7933 11.7067L11.8199 10.76Z"
        // fill={fill || 'white'}
      />
      <path
        d="M4.18015 10.76L3.22015 11.6867C3.15766 11.7486 3.10807 11.8224 3.07422 11.9036C3.04037 11.9849 3.02295 12.072 3.02295 12.16C3.02295 12.248 3.04037 12.3352 3.07422 12.4164C3.10807 12.4976 3.15766 12.5714 3.22015 12.6333C3.28268 12.6971 3.35739 12.7477 3.43984 12.7821C3.5223 12.8164 3.61082 12.8339 3.70015 12.8333C3.86449 12.8347 4.02356 12.7754 4.14681 12.6667L5.10681 11.74C5.23412 11.6171 5.30739 11.4487 5.31052 11.2718C5.31364 11.0949 5.24637 10.924 5.12348 10.7967C5.0006 10.6694 4.83218 10.5961 4.65527 10.593C4.47836 10.5898 4.30745 10.6571 4.18015 10.78V10.76Z"
        // fill={fill || 'white'}
      />
      <path
        d="M8.00016 5.33333C7.47275 5.33333 6.95718 5.48973 6.51864 5.78274C6.08011 6.07576 5.73832 6.49224 5.53649 6.97951C5.33465 7.46677 5.28184 8.00295 5.38474 8.52024C5.48763 9.03752 5.74161 9.51267 6.11455 9.88561C6.48749 10.2586 6.96264 10.5125 7.47992 10.6154C7.99721 10.7183 8.53338 10.6655 9.02065 10.4637C9.50792 10.2618 9.9244 9.92005 10.2174 9.48151C10.5104 9.04298 10.6668 8.52741 10.6668 7.99999C10.6668 7.29275 10.3859 6.61447 9.88578 6.11438C9.38569 5.61428 8.70741 5.33333 8.00016 5.33333Z"
        // fill={fill || 'white'}
      />
    </svg>
  );

Sun.defaultProps = {
  width: 16,
  height: 16,
  // fill: '#6E7DAE',
  outline: true,
};

Sun.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  // fill: PropTypes.string,
  outline: PropTypes.bool,
};

export default Sun;
