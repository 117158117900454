import React from 'react';
import {Select} from "antd";
const { Option } = Select;

const SelectRole = (props) => {
  const {data, onChange, defaultValue, id} = props
  //creating options for dropdown
  const options = data?.map(el => <Option key={el.id} value={el.id}>{el.title}</Option>)

  return (
    <Select id={id}
            //default value passed for modal select (create/edit)
            //default passed as int (user_roles: [{id: 1, title: "User"}, ...])
            defaultValue={defaultValue ? defaultValue : 'All roles'}
            style={{ width: 120 }}
            onChange={onChange}>
      {/*if default value not passed, add All Roles option (filtering table data)*/}
      {!defaultValue && <Option key={999999} value={'All roles'}>All roles</Option>}
      {options}
    </Select>
  );
};

export default SelectRole;
