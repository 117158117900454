import React, {useState, useEffect, useRef} from 'react'
import {useHistory} from 'react-router-dom'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import Creators from './reducer'

import Layout from '../Layout'
import {OverlayScrollbar} from '../../Components/ScrollBar'

import {Row, Col, Spin, Card} from 'antd'
import LoadingPage from '../LoadingPage'
import './styles.less'

const {Meta} = Card

const data = [
  {
    id: '11hj1',
    image: 'https://www.stateofartacademy.com/wp-content/uploads/2021/09/xan_Cam001.jpg',
    title: 'Model 1',
  },
  {
    id: '11hj2',
    image: 'https://www.stateofartacademy.com/wp-content/uploads/2021/11/0001_TreeHouse_cam001.jpg',
    title: 'Model 2',
  },
  {
    id: '11hj3',
    image: 'https://www.stateofartacademy.com/wp-content/uploads/2021/11/0002_AsaHouse_cam014.jpg',
    title: 'Model 3',
  },
  {
    id: '11hj4',
    image: 'https://www.stateofartacademy.com/wp-content/uploads/2021/11/0003_MAhouse_001.jpg',
    title: 'Model 4',
  },
]

export const DashboardPage = (
  {
    fetching,
    fetchingProducts,
    error,
    dashboardInfo,
    userInfo,
    isMobile,
    theme,
    pixelStreaming,
    ...props
  }) => {
  const [firsLoading, setFirsLoading] = useState(true)
  const scrollRef = useRef()
  const history = useHistory()

  useEffect(() => {
    document.title = 'Main - MetaSOA'
    props.getDashboardInfo({})
    if (firsLoading) setTimeout(() => setFirsLoading(false), 3000)
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [])

  const handleGoPixelStreaming = (id) => {
    if(!pixelStreaming.result) return
    history.push(`/pixel_streaming/${id}`)
  }

  const dataModels = dashboardInfo?.results?.length ? dashboardInfo?.results : data

 if(fetching || fetchingProducts || pixelStreaming?.fetching) return <LoadingPage/>

  return (
    <Layout>
      <OverlayScrollbar
        ref={scrollRef}
        //onScroll={() => onScrollHandler(dashboardInfo?.next)}
        visibility={'hidden'}
      >
        <div className={'dashboard-page'}>
          <Spin size="large"
                spinning={pixelStreaming?.fetching}
                tip={pixelStreaming?.fetching ? "Instance Loading..." : ''}
          >
            <Row gutter={[16, 16]}>
              {
                dataModels.map(el => (
                  <Col key={el?.id} xs={24} sm={24} md={12} lg={6}>
                    <Card
                      hoverable
                      //style={{width: 240}}
                      cover={<img alt="example" src={el?.image}/>}
                      aria-disabled={!pixelStreaming.result}
                      onClick={() => handleGoPixelStreaming(el?.['model_id'])}
                    >
                      <Meta title={el?.title}
                            //description={el?.description || ''}
                      />
                    </Card>
                  </Col>
                ))
              }
            </Row>
          </Spin>
          {/* <Button type={'primary'} onClick={handleSendMessage}>
            ws hello
          </Button>*/}
        </div>
      </OverlayScrollbar>
    </Layout>
  )
}

DashboardPage.defaultProps = {
  dashboardInfo: {
    previous: null,
    next: null,
    results: [],
  },
}

DashboardPage.propTypes = {
  dashboardInfo: PropTypes.shape({
    previous: PropTypes.string,
    next: PropTypes.string,
    results: PropTypes.array,
  }),
  fetching: PropTypes.bool,
  fetchingProducts: PropTypes.bool,
  isMobile: PropTypes.bool,
  theme: PropTypes.string,
}

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  fetching: state.dashboard.fetching,
  fetchingProducts: state.dashboard.fetchingProducts,
  error: state.dashboard.errors,
  dashboardInfo: state.dashboard.result,
  isMobile: state.nav.isMobile,
  theme: state.nav.theme,
  pixelStreaming: state.pixelStreaming,
})

const mapDispatchToProps = (dispatch) => ({
  getDashboardInfo: (params) =>
    dispatch(Creators.getDashboardInfoRequest(params)),
  getNewDashboardInfo: (params) =>
    dispatch(Creators.getNewDashboardInfoRequest(params)),
  getNextDashboardInfo: (params) =>
    dispatch(Creators.getNextDashboardInfoRequest(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage)
