import React from 'react'

const PlusIcon = ({width, height, color, ...props}) => (
  <svg width={width ? width : '48'}
       height={height ? height : '48'}
       fill={color ? color : '#6E7DAF'}
       {...props.style}
       viewBox="0 0 48 48"
       xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M38 22H26V10C26 9.46957 25.7893 8.96086 25.4142 8.58579C25.0391 8.21071 24.5304 8 24 8C23.4696 8 22.9609 8.21071 22.5858 8.58579C22.2107 8.96086 22 9.46957 22 10V22H10C9.46957 22 8.96086 22.2107 8.58579 22.5858C8.21071 22.9609 8 23.4696 8 24C8 24.5304 8.21071 25.0391 8.58579 25.4142C8.96086 25.7893 9.46957 26 10 26H22V38C22 38.5304 22.2107 39.0391 22.5858 39.4142C22.9609 39.7893 23.4696 40 24 40C24.5304 40 25.0391 39.7893 25.4142 39.4142C25.7893 39.0391 26 38.5304 26 38V26H38C38.5304 26 39.0391 25.7893 39.4142 25.4142C39.7893 25.0391 40 24.5304 40 24C40 23.4696 39.7893 22.9609 39.4142 22.5858C39.0391 22.2107 38.5304 22 38 22Z"
      />
  </svg>
)

export default PlusIcon
