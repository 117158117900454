import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

// REDUCER FUNCTIONS
import {
  verifyTokenFailure,
  verifyTokenSuccess,
  verifyTokenRequest,
  refreshTokenRequest,
  refreshTokenSuccess,
  refreshTokenFailure,
} from './reducerFunc';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  signInRequest: ['email', 'password'],
  signInSuccess: ['userInfo', 'accessToken', 'refreshToken'],
  signInFailure: ['error'],

  refreshTokenRequest: ['payload'], // payload: { token }
  refreshTokenSuccess: ['payload'], // payload: { data }
  refreshTokenFailure: ['payload'], // payload: { errors }

  verifyTokenRequest: ['payload'], // payload: { token }
  verifyTokenSuccess: ['payload'], // payload: { data }
  verifyTokenFailure: ['payload'], // payload: { errors }

  authErrorReset: null,

  updateTokens: ['accessToken', 'refreshToken'],

  checkEmailRequest: ['data'],
  changeEmailRequest: ['data'],
  confirmEmailRequest: ['hash', 'redirect'],

  checkPasswordRequest: ['email'],
  confirmPasswordRequest: ['code'],

  logout: null,
  clearState: null,

  updateProfile: ['data'],

  signUpRequest: ['password', 'isNewUserCheck'],
  signUpSuccess: ['data'],
  signUpFailure: ['error'],

  setRemember: ['data'],

  authFetchingReset: null,

  setOnBoardingFinished: ['onboarding_finished'],

  getUserInfoRequest: null,
  getUserInfoSuccess: ['userInfo'],
});

export const AuthTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  userInfo: null,
  accessToken: null,
  refreshToken: null,
  isValidRefreshToken: false,
  error: null,
  fetching: false,

  updateFetching: false,
  updateErrors: false,

  isAdmin: false,
  role: null,

  rememberMe: null,
});

/* ------------- Selectors ------------- */

export const AuthSelectors = {
  getUserInfo: (state) => state.auth.userInfo,
  getAccessToken: (state) => state.auth.accessToken,
  getRefreshToken: (state) => state.auth.refreshToken,
};

/* ------------- Reducers ------------- */

export const signInRequest = (state, { email, password }) =>
  state.merge({ fetching: true, error: null });

export const signInSuccess = (
  state,
  { userInfo, accessToken, refreshToken },
) => {
  const isAdmin = userInfo?.role?.id === 2;
  const role = userInfo?.role?.id;
  return state.merge({
    fetching: false,
    error: null,
    userInfo,
    accessToken,
    refreshToken,
    isAdmin,
    role,
  });
};

export const signInFailure = (state, { error }) =>
  state.merge({ error, fetching: false });

export const authErrorReset = (state) =>
  state.merge({ error: null, fetching: false });

export const updateTokens = (state, { accessToken, refreshToken }) =>
  state.merge({ accessToken, refreshToken });

export const checkEmailRequest = (state, { data }) =>
  state.merge({ fetching: true, error: null });

export const changeEmailRequest = (state, { data }) =>
  state.merge({ fetching: true, error: null });

export const confirmEmailRequest = (state, { hash, redirect }) =>
  state.merge({ fetching: true, error: null });

export const checkPasswordRequest = (state, { email }) =>
  state.merge({ fetching: true, error: null });

export const confirmPasswordRequest = (state, { code }) =>
  state.merge({ fetching: true, error: null });

export const logout = (state) =>
  state.merge({ ...INITIAL_STATE, rememberMe: state.rememberMe });

export const clearState = (state) => state.merge({ ...INITIAL_STATE });

export const updateProfile = (state, { data }) => {
  let userInfo = {};
  if (data?.user) {
    userInfo = { ...state.userInfo, ...data.user };
  } else {
    userInfo = { ...state.userInfo, ...data };
  }

  return state.merge({ userInfo });
};

export const getUserInfoRequest = (state) =>
  state.merge({ fetching: true, error: null });

export const getUserInfoSuccess = (state, {userInfo}) => {
  return state.merge({fetching: false, userInfo });
}

export const setOnBoardingFinished = (state, {onboarding_finished}) => {
  return state.merge({ userInfo: {...state.userInfo , onboarding_finished} });
}

export const signUpRequest = (state, { password, isNewUserCheck }) =>
  state.merge({ fetching: true, error: null });

export const signUpSuccess = (state, { data }) => {
  if(state.userInfo){
   return state.merge({
     fetching: false,
     error: null,
     userInfo: {...state?.userInfo, 'onboarding_finished': !!data?.onboarding_finished}});
  }else{
    return state.merge({
      fetching: false,
      error: null,
      userInfo: {'onboarding_finished': !!data?.onboarding_finished}
    })
}
}

export const signUpFailure = (state, { error }) =>
  state.merge({ error, fetching: false });

export const setRemember = (state, { data }) =>
  state.merge({ rememberMe: data });

export const authFetchingReset = (state) =>
  state.merge({
    fetching: false,
    updateFetching: false,
  });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SIGN_IN_REQUEST]: signInRequest,
  [Types.SIGN_IN_SUCCESS]: signInSuccess,
  [Types.SIGN_IN_FAILURE]: signInFailure,

  [Types.AUTH_ERROR_RESET]: authErrorReset,

  [Types.UPDATE_TOKENS]: updateTokens,

  [Types.CHECK_EMAIL_REQUEST]: checkEmailRequest,
  [Types.CHANGE_EMAIL_REQUEST]: changeEmailRequest,
  [Types.CONFIRM_EMAIL_REQUEST]: confirmEmailRequest,

  [Types.CHECK_PASSWORD_REQUEST]: checkPasswordRequest,
  [Types.CONFIRM_PASSWORD_REQUEST]: confirmPasswordRequest,

  [Types.LOGOUT]: logout,
  [Types.CLEAR_STATE]: clearState,

  [Types.UPDATE_PROFILE]: updateProfile,

  [Types.SIGN_UP_REQUEST]: signUpRequest,
  [Types.SIGN_UP_SUCCESS]: signUpSuccess,
  [Types.SIGN_UP_FAILURE]: signUpFailure,

  [Types.SET_REMEMBER]: setRemember,

  [Types.AUTH_FETCHING_RESET]: authFetchingReset,

  [Types.REFRESH_TOKEN_REQUEST]: refreshTokenRequest,
  [Types.REFRESH_TOKEN_SUCCESS]: refreshTokenSuccess,
  [Types.REFRESH_TOKEN_FAILURE]: refreshTokenFailure,

  [Types.VERIFY_TOKEN_REQUEST]: verifyTokenRequest,
  [Types.VERIFY_TOKEN_SUCCESS]: verifyTokenSuccess,
  [Types.VERIFY_TOKEN_FAILURE]: verifyTokenFailure,

  [Types.SET_ON_BOARDING_FINISHED]: setOnBoardingFinished,

  [Types.GET_USER_INFO_REQUEST]: getUserInfoRequest,
  [Types.GET_USER_INFO_SUCCESS]: getUserInfoSuccess,
});
