import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Router, Switch, Route, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Layout } from 'antd';
import { withResizeDetector } from 'react-resize-detector';
import { history } from '../../Utils/utils';

// COMPONENTS
import { PrivateRoute } from '../../Components/PrivateRoute';
import StartupPage from '../StartupPage';
import LoginPage from '../LoginPage';
import NotFoundPage from '../NotFoundPage';
//import SignUpPage from '../SignUpPage';
//import SignUpConfirmPage from '../SignUpConfirmPage';
//import CheckMailboxPage from '../CheckMailboxPage';
//import RestorePasswordPage from '../RestorePasswordPage';
//import VerifyCodePage from '../VerifyCodePage';
//import NewPasswordPage from '../NewPasswordPage';
//import SuccessPage from '../SuccessPage';
//import ExpiredPage from '../ExpiredPage';
//import AlreadyRegisteredPage from '../AlreadyRegisteredPage';
import DashboardPage from '../DashboardPage';
import PixelStreaming from '../PixelStreaming';

//import SettingPage from '../SettingPage';
import UsersAdminPage from '../UsersPageAdmin';

import openNotification from '../../Components/Notification';


// REDUCERS
import NavActions from '../../Redux/NavRedux';
import ErrorsActions from '../../Redux/ErrorsRedux';
import AuthActions from '../LoginPage/reducer';
import StartupCreators from '../../Redux/StartupRedux';
import PixelStreamingCreators from '../PixelStreaming/reducer';

// HOC
import useWindowSize from '../../hooks/use-window-size';
import withAuth from '../../hoc/withAuth';

function Routes(props) {
  const {
    width,
    auth,
    navSetProp,
    userInfo,
    isAdmin,
    error,
    verifyToken,
    startup,
    dispatch,
  } = props;

  const {pathname} = useLocation();
  const [, innerHeight] = useWindowSize();
  const [wsChannel, setWsChannel] = useState(null)

  // STARTUP
  useEffect(() => {
    dispatch(StartupCreators.startup());

    return () => {
      wsChannel.removeEventListener('open', openHandler)
      wsChannel.removeEventListener('message', messageHandler)
      wsChannel.removeEventListener('close', closeHandler)
      wsChannel.close()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    if(innerHeight) document.documentElement.style.setProperty('--app-height', `${innerHeight}px`)
  }, [innerHeight]);

  useEffect(() => {
   if(pathname === '/login' && wsChannel){
     console.log('pathname', pathname)
     dispatch(PixelStreamingCreators.instanceReset())
     wsChannel.close()
   }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [pathname])

  // REFRESH TOKEN
  useEffect(() => {
    if (auth?.accessToken && auth?.userInfo?.onboarding_finished) {
      verifyToken(auth.accessToken);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [auth?.accessToken, auth?.refreshToken]);

  useEffect(() => {
    if (auth?.accessToken && auth?.userInfo?.onboarding_finished && !isAdmin && !wsChannel) {
     createChannel(auth?.accessToken)
    }else if(!auth?.accessToken){
      clearListenerWsChannel()
      setWsChannel(null)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [auth?.accessToken, auth?.refreshToken, wsChannel]);

  useEffect(() => {
    if(wsChannel) {
      wsChannel.addEventListener('open', openHandler)
      wsChannel.addEventListener('message', messageHandler)
      wsChannel.addEventListener('close', closeHandler)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  },[wsChannel])

  // ERRORS
  useEffect(() => {
    if (error) {
      if (error.message) {
        for (const i in error.message) {
          //message.error(`${i}: ${error.message[i]}`, 5);
          openNotification({
            type: 'error',
            message: `${i}: ${error.message[i]}`,
            style: { minWidth: '716px' },
            getContainer: userInfo?.onboarding_finished ? document.getElementById('global-wrap') : document.body,
            duration: 5,
            key: i,
          });
        }
      } else if (typeof error === 'string') {
        //message.error(error, 5);
        openNotification({
          type: 'error',
          message: error,
          style: { minWidth: '716px' },
          getContainer: userInfo?.onboarding_finished ? document.getElementById('global-wrap') : document.body,
          duration: 5,
          key: 'error notification',
        });
      } else {
        for (const i in error) {
          //message.error(`${i}: ${error[i]}`, 5);
          openNotification({
            type: 'error',
            message: `${i}: ${error[i]}`,
            style: { minWidth: '716px' },
            getContainer: userInfo?.onboarding_finished ? document.getElementById('global-wrap') : document.body,
            duration: 5,
            key: i,
          });
        }
      }
      props.errorReset();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [!!error]);

  // RESIZE
  useEffect(() => {
    const correctWidth = width || window.innerWidth
    if (correctWidth > 768) {
      navSetProp('isDesktop', true);
      navSetProp('isMobile', false);
    } else {
      navSetProp('isDesktop', false);
      navSetProp('isMobile', true);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [width]);

  const createChannel = () => {
    if(!props.auth?.accessToken) return
    clearListenerWsChannel()
    setWsChannel(new WebSocket(`wss://digitalhub-showroom.com/web-socket/?${auth?.accessToken}`))
  }

  function clearListenerWsChannel(){
    if(wsChannel){
      wsChannel.removeEventListener('open', openHandler)
      wsChannel.removeEventListener('message', messageHandler)
      wsChannel.removeEventListener('close', closeHandler)
      wsChannel.close()
    }
  }

  const openHandler = (e) => {
    console.log('OPEN WS', e)
    dispatch(PixelStreamingCreators.getInstanceRequest())

    setTimeout(()=> wsChannel.send(JSON.stringify({"key": "test"})), 5000)
  }
  const messageHandler = (e) => {
    let data = JSON.parse(e.data)?.data || {}
    if(data?.status === 'Ready'){
      dispatch(PixelStreamingCreators.getInstanceSuccess(data.ip))
    }
    console.log('MESSAGE WS', data)
  }
  function closeHandler (e) {
    console.log('CLOSE WS', e)
    dispatch(PixelStreamingCreators.instanceReset())
    setTimeout(createChannel, 3000)
  }

  if (startup?.success === false && !auth.isValidRefreshToken) {
    return (
      <Layout>
        <Route path="*" component={StartupPage} />
      </Layout>
    );
  }

  return (
    <Router history={history}>
      <Layout className="main-layout">
        <Switch>
          <Route exact path="/" component={StartupPage} />
          <Route path="/login" component={LoginPage} />
          {/*<Route exact path="/sign-up" component={SignUpPage} />
          <Route path="/add-email" component={SignUpPage} />
          <Route path="/check-mailbox" component={CheckMailboxPage} />
          <Route path="/sign-up/confirm/:hash" component={SignUpConfirmPage} />
          <Route exact path="/restore-password" component={RestorePasswordPage}/>
          <Route path="/verify-code" component={VerifyCodePage} />
          <Route path="/restore-password/:hash" component={NewPasswordPage} />
          <Route path="/success/:hash" component={SuccessPage} />
          <Route path="/success" component={SuccessPage} />
          <Route path="/expired" component={ExpiredPage} />
          <Route path="/already-registered" component={AlreadyRegisteredPage} />*/}

          <PrivateRoute
            path="/main"
            redirectPath="/login"
            auth={!!auth?.accessToken && userInfo?.onboarding_finished && !isAdmin}
            component={DashboardPage}
          />
          <PrivateRoute
            path="/pixel_streaming/:modelId"
            redirectPath="/login"
            auth={!!auth?.accessToken && userInfo?.onboarding_finished && !isAdmin}
            component={PixelStreaming}
          />

          {/*<PrivateRoute
            exact
            path="/settings"
            redirectPath="/login"
            auth={!!auth?.accessToken && userInfo?.onboarding_finished}
            component={SettingPage}
          />
          <PrivateRoute
            path="/settings/:hash"
            redirectPath="/login"
            auth={!!auth?.accessToken && userInfo?.onboarding_finished}
            component={SettingPage}
          />*/}

          <PrivateRoute
            exact
            path="/admin/customers"
            redirectPath="/login"
            auth={!!auth?.accessToken && isAdmin}
            component={UsersAdminPage}
          />

          <Route path="/*" component={NotFoundPage} />
        </Switch>

      </Layout>
    </Router>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  userInfo: state.auth.userInfo,
  startup: state.startup,
  isDesktop: state.nav.isDesktop,
  isMobile: state.nav.isMobile,
  isAdmin: state.auth.isAdmin,
  error: state.errors.data,
});

const mapDispatchToProps = (dispatch) => ({
  navSetProp: (key, value) => dispatch(NavActions.navSetProp(key, value)),
  errorReset: () => dispatch(ErrorsActions.errorReset()),
  refreshToken: (token) => dispatch(AuthActions.refreshTokenRequest({ token })),
  logout: () => dispatch(AuthActions.logout()),
  verifyToken: (token) => dispatch(AuthActions.verifyTokenRequest({ token })),
  dispatch,
});

Routes.propTypes = {
  auth: PropTypes.shape({
    accessToken: PropTypes.string,
    refreshToken: PropTypes.string,
    isValidRefreshToken: PropTypes.bool,
  }),
  userInfo: PropTypes.shape({
    onboarding_finished: PropTypes.bool,
  }),
  startup: PropTypes.shape({
    success: PropTypes.bool,
  }),
  isDesktop: PropTypes.bool,
  isMobile: PropTypes.bool,
  isAdmin: PropTypes.bool,
  error: PropTypes.any,
  dispatch: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(compose(withResizeDetector, withAuth)(Routes));
